import { environment } from '../../../environments/environment';
import { Observable, of, throwError, Subject } from 'rxjs';
import { AuthorizedHttp } from './authorized-http.service';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ProfileSummary } from '../data-models/ProfileSummary';
import { CommonService } from "./common.service";

@Injectable()
export class ProfileSummaryService {
    private _apiEndpoint = `${environment.apiEndpoint}/api/profilesummary`;
    private _profileSummaries: ProfileSummary[];
    private _profileSummary: ProfileSummary;
    private _isDeleted: Boolean;

    constructor(private http: AuthorizedHttp, private commonService: CommonService) {
      let  headers: any = {
          'Content-Type': 'application/json; charset=utf-8', /*or whatever type is relevant */
          'Accept': 'application/json' 
      }
    }

    public getProfileSummaries(userId: string): Observable<ProfileSummary[]> {
      userId = this.commonService.encrypt(userId);
      return this.http
          .get(`${this._apiEndpoint}/myProfileSummaries/` + userId)
          .pipe(
            map(result => {
              this._profileSummaries = result;
              return this._profileSummaries;
            }),
            catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
          );
      }

    public postNewProfileSummary(profileSummary: ProfileSummary, userId: string): Observable<ProfileSummary> {
      userId = this.commonService.encrypt(userId);
      return this.http
          .post(`${this._apiEndpoint}/addNewProfileSummary/`+ userId, JSON.stringify(profileSummary))
          .pipe(
            map(result => {
              this._profileSummary = result;
              return this._profileSummary;
            }),
            catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
          );
      }
    public updateProfileSummary(profileSummary: ProfileSummary, userId: string): Observable<ProfileSummary> {
      userId = this.commonService.encrypt(userId);
      return this.http
          .patch(`${this._apiEndpoint}/updateProfileSummary/` + userId, JSON.stringify(profileSummary))
          .pipe(
            map(result => {
              this._profileSummary = result;
              return this._profileSummary;
            }),
            catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
          );
      }

    public deleteProfileSummary(profileSummary: ProfileSummary, userId: string): Observable<ProfileSummary> {
      userId = this.commonService.encrypt(userId);
        return this.http
            .patch(`${this._apiEndpoint}/deleteProfileSummary/` + userId, JSON.stringify(profileSummary))
            .pipe(
                map(result => {
                    this._profileSummary = result;
                    return this._profileSummary;
                }),
                catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
            );
    }
}
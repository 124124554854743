import { PickListComponent } from "./pick-list/pick-list.component";
import { ApprovalDialogComponent } from "./approval-dialog/approval-dialog.component";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule, DatePipe } from "@angular/common";
import { RouterModule } from "@angular/router";
import { SessionExpiredDialogComponent } from "./session-expired-dialog/session-expired-dialog.component";
import { TranslateModule } from "@ngx-translate/core";
import { IconModule } from "@visurel/iconify-angular";
import { MatButtonModule } from "@angular/material/button";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatIconModule } from "@angular/material/icon";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatDialogModule } from "@angular/material/dialog";
import { CommentDialogComponent } from "./comment-dialog/comment-dialog.component";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

import { maxDateRangeValidatorDirective } from "src/app/shared/max-date-range-validator.directive";
import { minDateRangeValidatorDirective } from "src/app/shared/min-date-range-validator.directive";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { AutoCompleteInputComponent } from "./autocomplete/autocomplete-input.component";
import { AutoCompleteFormFieldComponent } from "./autocomplete-form-field/autocomplete-form-field.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { ResponsibilityItemComponent } from "./projects/responsibilities/responsibility-item/responsibility-item.component";
import { ProjectResponsibilityInput } from "./projects/responsibilities/responsibility-input/responsibility-input.component";
import { ResponsibilityContainer } from "./projects/responsibilities/responsibility-container.component";
import { MatCardModule } from "@angular/material/card";
import { MatListModule } from "@angular/material/list";
import { DragDropListCardComponent } from "./projects/drag-drop-list-card/drag-drop-list-card.component";
import { SkillsListComponent } from "./skills-list/skills-list.component";
import { MatTableModule } from "@angular/material/table";
import { MatSelectModule } from "@angular/material/select";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MultiSelectOption } from "../build-resume/build-resume-card/multi-select-card/multi-select-card.component";
import { MultiSelectContainerComponent } from "./multi-select-container/multi-select-container.component";
import { SkillCompetencyCategoryListComponent } from './skill-competency-category-list/skill-competency-category-list.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IconModule,
        MatButtonModule,
        MatTooltipModule,
        MatDialogModule,
        MatCardModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        RouterModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        TranslateModule,
        FlexLayoutModule,
        ScrollingModule,
        MatListModule,
        MatTableModule,
        MatSelectModule,
        DragDropModule,
    ],
    declarations: [
        ResponsibilityItemComponent,
        ProjectResponsibilityInput,
        ResponsibilityContainer,
        ConfirmationDialogComponent,
        CommentDialogComponent,
        ApprovalDialogComponent,
        PickListComponent,
        SessionExpiredDialogComponent,
        maxDateRangeValidatorDirective,
        minDateRangeValidatorDirective,
        AutoCompleteInputComponent,
        AutoCompleteFormFieldComponent,
        DragDropListCardComponent,
        SkillsListComponent,
        MultiSelectContainerComponent,
        SkillCompetencyCategoryListComponent,
    ],
    exports: [
        CommonModule,
        FormsModule,
        IconModule,
        MatButtonModule,
        MatDialogModule,
        FlexLayoutModule,
        MatIconModule,
        MatInputModule,
        MatMomentDateModule,
        ReactiveFormsModule,
        TranslateModule,
        maxDateRangeValidatorDirective,
        minDateRangeValidatorDirective,
        ResponsibilityItemComponent,
        ProjectResponsibilityInput,
        ResponsibilityContainer,
        ConfirmationDialogComponent,
        CommentDialogComponent,
        ApprovalDialogComponent,
        AutoCompleteInputComponent,
        AutoCompleteFormFieldComponent,
        DragDropListCardComponent,
        SkillsListComponent,
        MultiSelectContainerComponent,
        SkillCompetencyCategoryListComponent,
    ]
})
export class SharedModule {}

export class MatchType {
  public static MatchData<T>(data: any, type: new () => T): T {
    let result: T = new type();
    Object.assign(result, data);
    return result;
  }
  public static MatchDataArray<T>(datas: any[], type: new () => T): T[] {
    let result: T[] = [];
    datas.forEach((data) => {
      result.push(MatchType.MatchData<T>(data, type));
    });
    return result;
  }
}

import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Subscription } from "rxjs";
import { Resume } from "src/app/common/data-models/Resume";
import { ResumeSearchUtilService } from "src/app/common/services/resume-search-util.service";
import { OnlinerService } from "src/app/common/services/onliner.service";
import { ResumePdfBuilderService } from "../../common/services/resume-pdf-builder.service";
import { ResumeDocxBuilderService } from "../../common/services/resume-docx-builder.service";
import { ResumeService } from "src/app/common/services/resume.service";
import { AuthService } from "src/app/common/services/auth.service";
import { GlobalConstants } from "src/app/shared/global-constants";
import { UserService } from "../../common/services/user.service";

export class ApprovedResumeDisplayData {
  public id: number;
  public isActive: boolean;
  public modifiedDate: Date;
  public isMaster: boolean;
}

enum DOWNLOADABLE_DOC_TYPE {
  PDF,
  WORD,
}

@Component({
  selector: "obs-resumes-search-results",
  templateUrl: "search-results.component.html",
  styleUrls: ["search-results.component.scss"],
})
export class ResumesSearchResults implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  DOWNLOADABLE_DOC_TYPE = DOWNLOADABLE_DOC_TYPE;
  readonly APPROVED_RESUME = "Approved";

  public searchResumesDisplayData = new MatTableDataSource<Resume>();
  public careerManager: string;
  public resume: Resume;
  private _resultSubscription: Subscription;
  private selectedDocType: DOWNLOADABLE_DOC_TYPE;
  displayedColumns: string[] = [
    "name",
    "status",
    "modifiedDate",
    "careerManager",
    "attachment",
    "clientInfo",
  ];
  private userId: string;
  public isLoading = false;
  public nothingFound = false;
  public isGeneratingDocument = false;
  public downloadingElementRowIndex: number;
  public dateFormat = GlobalConstants.FULL_DATE_FORMAT;

  constructor(
    private _resumeSearchUtil: ResumeSearchUtilService,
    private _dialog: MatDialog,
    private _resumePdfBuilderService: ResumePdfBuilderService,
    private _resumeDocxBuilderService: ResumeDocxBuilderService,
    private _resumeService: ResumeService,
    private _authService: AuthService,
    private _onlinerService: OnlinerService,
    private _userService: UserService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this._resumeSearchUtil.searchResumeResults().subscribe((data) => {
      this.searchResumesDisplayData.data = data.resumes.filter(
        (r) => r.dateApproved == r.maxApprovedSpinDate
      );
      this.careerManager = data.careerManager;
      this._onlinerService.getOnliner(data.careerManager).subscribe((data) => {
        data
          ? (this.careerManager = `${data.firstName} ${data.lastName}`)
          : null;
      });

      if (this.searchResumesDisplayData.data.length == 0) {
        this.nothingFound = true;
      } else {
        this.nothingFound = false;
      }
    });

    this._resumeSearchUtil.isSearching().subscribe((searching) => {
      this.isLoading = searching;
    });

    this.userId = this._authService.getUserId();
  }

  onSortData(sort: Sort) {
    let data = [...this.searchResumesDisplayData.data];
    if (sort.active && sort.direction !== "") {
      this.searchResumesDisplayData.data = data.sort(
        (a: Resume, b: Resume) => {
          const isAsc = sort.direction === "asc";

          if (!sort.active || sort.direction === "") return;

          switch (sort.active) {
            case "name":
              return this.compare(a.spins.name, b.spins.name, isAsc);
            case "modifiedDate":
              return this.compare(
                a.statusType.modifiedDate,
                b.statusType.modifiedDate,
                isAsc
              );
            case "clientInfo":
              return this.compare(a.isClientHidden, b.isClientHidden, isAsc);
            default:
              return 0;
          }
        }
      );
    }
  }

  private compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  onPreviewDocumentClicked(selectedResume) {
    console.log("event fot the preview document", selectedResume);
  }

  onCreateDocumentClicked(resumeId: number, docType: DOWNLOADABLE_DOC_TYPE) {
    this.isGeneratingDocument = true;
    this.downloadingElementRowIndex =
      this.searchResumesDisplayData.data.findIndex((el) => el.id == resumeId);

    const docTypeLabel = docType === DOWNLOADABLE_DOC_TYPE.PDF ? "PDF" : "WORD";
    this.selectedDocType = docType;
    this.downloadFile(resumeId, docTypeLabel);
  }
  private async downloadFile(resumeId: number, docTypeLabel: string) {
    this.resume = await this.loadResume(resumeId);
    this.resume.useOriginal = true;

    if (docTypeLabel == "WORD") {
      DOWNLOADABLE_DOC_TYPE.WORD.toString();
      this._resumeDocxBuilderService.download(this.resume);
    } else {
      DOWNLOADABLE_DOC_TYPE.PDF.toString();
      this._resumePdfBuilderService.download(this.resume);
    }
    this.isGeneratingDocument = false;
    this.downloadingElementRowIndex;
  }

  private async loadResume(resumeId: number): Promise<Resume> {
    return new Promise(async (resolve) => {
      this._resumeService
        .getSnapshotResume(this.userId, resumeId)
        .subscribe(async (resume: Resume) => {
          resolve(resume);
        });
    });
  }

  getIcon(isClientHidden: boolean): string {
    return isClientHidden ? "visibility_off" : "visibility";
  }

  ngOnDestroy() {
    this._resultSubscription?.unsubscribe();
  }
}

import { UntypedFormArray, UntypedFormGroup, FormControl } from "@angular/forms";
import { Component, EventEmitter, OnInit, Input, Output, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core";
import { CommonService, DropDown } from "src/app/common/services/common.service";
import { MatDialog } from "@angular/material/dialog";
import {
  ConfirmDialog,
  ConfirmationDialogComponent,
} from "src/app/shared/confirmation-dialog/confirmation-dialog.component";
import { RAttribute } from "src/app/common/data-models/commonDataModels";
import { Observable, Subject, of, timer } from "rxjs";
import { Store, select, } from "@ngrx/store";
import { State } from "src/app/build-resume-common/store/multi-select-skills-card.reducer";
import { map, startWith, takeUntil, last, take } from "rxjs/operators";
import { userSkillsFilterQuery } from "src/app/build-resume-common/store/multi-select-skills-card.selectors";
import * as userSkillsActions from "src/app/build-resume-common/store/multi-select-skills-card.actions";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem
} from '@angular/cdk/drag-drop';
import { MatAutocomplete, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { RResumeExpertiseGroup } from "src/app/common/data-models/RResumeExpertiseGroup";
import { RSkill } from "src/app/common/data-models/RSkill";
import { PickList, PickListComponent } from "src/app/shared/pick-list/pick-list.component";
import { AuthService } from "src/app/common/services/auth.service";
import { OnlinerSkill } from "../models/OnlinerSkill";

export class MultiSelectSkillsOption<T> implements DropDown<T> {
  id: number;
  value: number;
  viewValue: string;
  dateModified: Date;
  element: any;
  isSelected: boolean;
  icon?: string;

  constructor(dropDown: DropDown<T>, isSelected: boolean = false) {
    this.id = dropDown.id;
    this.value = dropDown.value;
    this.viewValue = dropDown.viewValue;
    this.dateModified = dropDown.dateModified;
    this.element = dropDown.element;
    this.isSelected = isSelected;
    this.icon = dropDown.icon;
  }
}

@Component({
  selector: 'obs-multi-select-skills-card',
  templateUrl: './multi-select-skills-card.component.html',
  styleUrls: ['./multi-select-skills-card.component.scss'],
})
export class MultiSelectSkillsCardComponent<T> implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  expertiseType$: Observable<RAttribute[] | null>;
  @Input() parentForm: UntypedFormGroup;
  @Input() formArrayName: string;
  @Input() objectDropDown: DropDown<T>[];
  @Input() title: string;
  @Input() fieldLabel: string;
  @Input() readOnlyForm: boolean;
  @Input() leftTitle: string;
  @Input() resumeId: number;
  @Input() refreshList: boolean;
  @Input() sortList: (
    list: Map<number, DropDown<T>>
  ) => Map<number, DropDown<T>>;
  // output is error message
  @Input() validate: (
    selected: DropDown<T>,
    current: Map<number, DropDown<T>>
  ) => string;
  @Input() resumeExpertiseGroups: RResumeExpertiseGroup[];

  @Output() onRightSideChange: EventEmitter<RResumeExpertiseGroup[]> = new EventEmitter();

  expertiseTypes1: RAttribute[];
  expertiseType1: FormControl<string | RAttribute | { name: string }>;
  expertiseTypeOptions1: Observable<RAttribute[]>;
  expertiseTypes2: RAttribute[];
  expertiseType2: FormControl<string | RAttribute | { name: string }>;
  expertiseTypeOptions2: Observable<RAttribute[]>;
  expertiseTypes3: RAttribute[];
  expertiseType3: FormControl<string | RAttribute | { name: string }>;
  expertiseTypeOptions3: Observable<RAttribute[]>;
  expertiseTypes4: RAttribute[];
  expertiseType4: FormControl<string | RAttribute | { name: string }>;
  expertiseTypeOptions4: Observable<RAttribute[]>;
  isResetting: boolean;
  expertiseType1RASelected: RAttribute = null;
  expertiseType2RASelected: RAttribute = null;
  expertiseType3RASelected: RAttribute = null;
  expertiseType4RASelected: RAttribute = null;

  expertiseTypeOptions: RAttribute[];

  @ViewChild('auto1') autocomplete1: MatAutocomplete;
  @ViewChild('auto2') autocomplete2: MatAutocomplete;
  @ViewChild('auto3') autocomplete3: MatAutocomplete;
  @ViewChild('auto4') autocomplete4: MatAutocomplete;
  @ViewChild('autoTrigger1') autoTrigger1: MatAutocompleteTrigger;
  @ViewChild('autoTrigger2') autoTrigger2: MatAutocompleteTrigger;
  @ViewChild('autoTrigger3') autoTrigger3: MatAutocompleteTrigger;
  @ViewChild('autoTrigger4') autoTrigger4: MatAutocompleteTrigger;

  constructor(
    private authService: AuthService,
    private commonService: CommonService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private store: Store<{ userSkills: State }>
  ) {
    this.expertiseType1 = new FormControl();
    this.expertiseType2 = new FormControl();
    this.expertiseType3 = new FormControl();
    this.expertiseType4 = new FormControl();
  }

  private _leftList = new Map<number, DropDown<T>>();
  private _rightGroup1 = new Map<number, DropDown<T>>();
  private _rightGroup2 = new Map<number, DropDown<T>>();
  private _rightGroup3 = new Map<number, DropDown<T>>();
  private _rightGroup4 = new Map<number, DropDown<T>>();
  private userId: string;

  leftSide: MultiSelectSkillsOption<T>[];
  rightSide: MultiSelectSkillsOption<T>[];
  isPopulated: boolean;
  isEmpty: boolean;
  errorMessage: string;
  skillGroup1: MultiSelectSkillsOption<T>[] = [];
  skillGroup2: MultiSelectSkillsOption<T>[] = [];
  skillGroup3: MultiSelectSkillsOption<T>[] = [];
  skillGroup4: MultiSelectSkillsOption<T>[] = [];
  isSkillGroup1Enabled: boolean = false;
  isSkillGroup2Enabled: boolean = false;
  isSkillGroup3Enabled: boolean = false;
  isSkillGroup4Enabled: boolean = false;

  get formArray() {
    return this.parentForm.get(this.formArrayName) as UntypedFormArray;
  }

  get isReadOnly() {
    return this.readOnlyForm;
  }

  get isUserSkillsPageValid() {
    if(this.isReadOnly) return false;
    
    if(this.expertiseType1RASelected?.id > 0 && this.skillGroup1?.length <= 0) return false;
    
    if(this.expertiseType2RASelected?.id > 0 && this.skillGroup2?.length <= 0) return false;
    
    if(this.expertiseType3RASelected?.id > 0 && this.skillGroup3?.length <= 0) return false;
    
    if(this.expertiseType4RASelected?.id > 0 && this.skillGroup4?.length <= 0) return false;
    
    if(this.expertiseType1RASelected === null && 
      this.expertiseType2RASelected === null &&
      this.expertiseType3RASelected === null &&
      this.expertiseType4RASelected === null) return false;
    
    return true;
  }

  ngOnInit(): void {
    this.expertiseType$ = this.store.select(userSkillsFilterQuery.selectExpertiseType);
    let tempList = new Map<string, DropDown<T>>();
    this.userId = this.authService.getUserId();
    for (let i = 0; i < this.objectDropDown.length; i++) {
      let tempElement = this.objectDropDown[i];

      var foundElement = this.resumeExpertiseGroups?.find(x => x.skillId === tempElement.value);

      if (
        (!tempList.has(tempElement.viewValue) ||
        tempList.get(tempElement.viewValue).dateModified <
          tempElement.dateModified) && foundElement === undefined
      ) {
        tempList.set(tempElement.viewValue, tempElement);
      }
    }

    tempList.forEach((item) => {
      this._leftList.set(item.value, item);
    });

    this.formArray.controls.forEach((item) => {
      let includedItem = this._leftList.get(item.value);
      if (includedItem) {
        this._leftList.delete(includedItem.value);
      }
    });

    this.populateLeftSide();

    this.store.pipe(
      select(userSkillsFilterQuery.getResumeUserSkillsFilteredExpertiseTypeOptions),
      takeUntil(this.destroy$)
    )
    .subscribe(expertiseTypes => {
      this.expertiseTypeOptions = expertiseTypes != null ? expertiseTypes : [];
      this.expertiseTypes1 = expertiseTypes != null ? expertiseTypes : [];
      this.expertiseTypeOptions1 = of(expertiseTypes != null ? expertiseTypes : []);
      this.expertiseTypes2 = expertiseTypes != null ? expertiseTypes : [];
      this.expertiseTypeOptions2 = of(expertiseTypes != null ? expertiseTypes : []);
      this.expertiseTypes3 = expertiseTypes != null ? expertiseTypes : [];
      this.expertiseTypeOptions3 = of(expertiseTypes != null ? expertiseTypes : []);
      this.expertiseTypes4 = expertiseTypes != null ? expertiseTypes : [];
      this.expertiseTypeOptions4 = of(expertiseTypes != null ? expertiseTypes : []);
    });

    this.initializeDropDowns();

    this.populateDropDownAndListBoxes();

    if(this.readOnlyForm){
      this.expertiseType1.disable(); 
      this.expertiseType2.disable();
      this.expertiseType3.disable();
      this.expertiseType4.disable();
    }
  }

  ngOnChanges() {
    if (this.refreshList) {
      this.populateLeftSide();
    }
  }

  alert(message?: any): void {
    alert(message);
  }

  initializeDropDowns(): void {
    this.expertiseType1.valueChanges.pipe(
      startWith(""),
      map((val) => {
        var filterVal = val;
        if (typeof val == 'object') {
          filterVal = this.optionDisplay(val as RAttribute);
          this.onFilterExpertiseType1(filterVal);
        } else {
          this.onFilterExpertiseType1(filterVal as string);
        }
      }),
      takeUntil(this.destroy$)
    ).subscribe();

    this.expertiseType2.valueChanges.pipe(
      startWith(""),
      map((val) => {
        var filterVal = val;
        if (typeof val == 'object') {
          filterVal = this.optionDisplay(val as RAttribute);
        this.onFilterExpertiseType2(filterVal);
        } else {
          this.onFilterExpertiseType2(filterVal as string);
        }
      }),
      takeUntil(this.destroy$)
    ).subscribe();

    this.expertiseType3.valueChanges.pipe(
      startWith(""),
      map((val) => {
        var filterVal = val;
        if (typeof val == 'object') {
          filterVal = this.optionDisplay(val as RAttribute);
        this.onFilterExpertiseType3(filterVal);
        } else {
          this.onFilterExpertiseType3(filterVal as string);
        }
      }),
      takeUntil(this.destroy$)
    ).subscribe();

    this.expertiseType4.valueChanges.pipe(
      startWith(""),
      map((val) => {
        var filterVal = val;
        if (typeof val == 'object') {
          filterVal = this.optionDisplay(val as RAttribute);
        this.onFilterExpertiseType4(filterVal);
        } else {
          this.onFilterExpertiseType4(filterVal as string);
        }
      }),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  populateDropDownAndListBoxes(): void {
    if (this.resumeExpertiseGroups?.length > 0) {
      this.resumeExpertiseGroups.sort((a, b) => {
        if (a.expertiseSortOrder !== b.expertiseSortOrder) {
          return a.expertiseSortOrder - b.expertiseSortOrder;
        } else {
          return a.sortOrder - b.sortOrder;
        }
      });

      //Drop down values
      const distinctExpertiseTypes: { expertiseSortOrder: number, expertiseTypeId: number }[] = [];
      this.resumeExpertiseGroups.forEach((group) => {
        const expertiseTypeId = group.expertiseTypeId;
        const expertiseSortOrder = group.expertiseSortOrder;
        const existingType = distinctExpertiseTypes.find((type) => type.expertiseTypeId === expertiseTypeId && type.expertiseSortOrder === expertiseSortOrder);
        if (!existingType) {
          distinctExpertiseTypes.push({ expertiseSortOrder, expertiseTypeId });
          switch(expertiseSortOrder) {
            case 1:
              this.expertiseTypeOptions1.subscribe((options) => {
                let selMatOption = options.find((o) => o.id === expertiseTypeId);
                this.expertiseType1.setValue(selMatOption.name);
                this.expertiseType1RASelected = selMatOption;
              });
              break;
            case 2:
              this.expertiseTypeOptions2.subscribe((options) => {
                let selMatOption = options.find((o) => o.id === expertiseTypeId);
                this.expertiseType2.setValue(selMatOption.name);
                this.expertiseType2RASelected = selMatOption;
              });
              break;
            case 3:
              this.expertiseTypeOptions3.subscribe((options) => {
                let selMatOption = options.find((o) => o.id === expertiseTypeId);
                this.expertiseType3.setValue(selMatOption.name);
                this.expertiseType3RASelected = selMatOption;
              });
              break;
            case 4:
              this.expertiseTypeOptions4.subscribe((options) => {
                let selMatOption = options.find((o) => o.id === expertiseTypeId);
                this.expertiseType4.setValue(selMatOption.name);
                this.expertiseType4RASelected = selMatOption;
              });
              break;
            default:
              break;
          }
        }
        this.store.dispatch(userSkillsActions.resetExpertiseTypeFilterValue());
      });

      // List box values
      this.resumeExpertiseGroups.forEach((group) => {
        const expertiseSortOrder = group.expertiseSortOrder;
        const skillGroup = this.getSkillGroup(expertiseSortOrder);

        if (skillGroup) {
          let listboxItem:
          MultiSelectSkillsOption<T> = {
            id: group.skillId,
            value: group.skillId,
            viewValue: group.skill.skill.name,
            dateModified: group.skill.skill.modifiedDate,
            element: group.skill,
            icon: null,
            isSelected: false,
          };
          skillGroup.push(listboxItem);
        };
      });

      this.populateLeftSide();
    }
  }

  private getSkillGroup(expertiseSortOrder: number): MultiSelectSkillsOption<T>[] {
    switch(expertiseSortOrder) {
      case 1:
        return this.skillGroup1;
      case 2:
        return this.skillGroup2;
      case 3:
        return this.skillGroup3;
      case 4:
        return this.skillGroup4;
      default:
        return [];
    }
  }

  private getExpertiseTypeSelected(expertiseSortOrder: number): RAttribute {
    switch(expertiseSortOrder) {
      case 1:
        return this.expertiseType1RASelected;
      case 2:
        return this.expertiseType2RASelected;
      case 3:
        return this.expertiseType3RASelected;
      case 4:
        return this.expertiseType4RASelected;
      default:
        return null;
    }
  }

  getSortedDropDown(dropDownMap: Map<number, DropDown<T>>): Map<number, DropDown<T>> {
    if (this.sortList) return this.sortList(dropDownMap);
    return new Map(
      [...dropDownMap].sort((a, b) =>
        String(a[1].viewValue.toUpperCase()).localeCompare(
          b[1].viewValue.toUpperCase()
        )
      )
    );
  }

  private populateResumeExpertiseGroupsFromDropDownsAndListBoxes(): void {
    this.resumeExpertiseGroups = [];
    let expertiseSortOrder = 1;
    for (let i = 1; i <= 4; i++) {
      let skillGroup = this.getSkillGroup(i);
      let expertiseTypeSelected = this.getExpertiseTypeSelected(i);

      if(skillGroup.length > 0 && expertiseTypeSelected?.id > 0) {
        this.populateResumeExpertiseGroups(skillGroup, expertiseTypeSelected, expertiseSortOrder++);
      }
    }
  }

  private populateResumeExpertiseGroups(skillGroup: MultiSelectSkillsOption<T>[], expertiseType: RAttribute, expertiseSortOrder: number): void {
    let sortOrder = 0;
    skillGroup.forEach((item) => {      
      let resumeExpertiseGroup = new RResumeExpertiseGroup(
        this.resumeId,
        expertiseType,
        expertiseType.id,
        item.element !== null ? item.element as unknown as RSkill : null,
        item.value,
        ++sortOrder,
        expertiseSortOrder
      );
      this.resumeExpertiseGroups.push(resumeExpertiseGroup);
    });
  }

  populateLeftSide(): void {
    this.leftSide = [];
    this.getSortedDropDown(this._leftList).forEach((item) => {
      let newItem = new MultiSelectSkillsOption(item);
      this.leftSide.push(newItem);
    });
  }

  clearErrorMessage(): void {
    this.errorMessage = null;
  }

  clearSelectedItems(groupListName: string): void {
    var noButtonClicked = false;
    var selectedItems: MultiSelectSkillsOption<T>[]
    let expertiseTypeName: string;
    var groupName: string;
    switch (groupListName) {
      case 'groupinglist1':
        selectedItems = this.skillGroup1;
        groupName = 'Skill Grouping 1';
        expertiseTypeName = this.expertiseType1RASelected?.name.trim();
        break;
      case 'groupinglist2':
        selectedItems = this.skillGroup2;
        groupName = 'Skill Grouping 2';
        expertiseTypeName = this.expertiseType2RASelected?.name.trim();;
        break;
      case 'groupinglist3':
        selectedItems = this.skillGroup3;
        groupName = 'Skill Grouping 3';
        expertiseTypeName = this.expertiseType3RASelected?.name.trim();;
        break;
      case 'groupinglist4':
        selectedItems = this.skillGroup4;
        groupName = 'Skill Grouping 4';
        expertiseTypeName = this.expertiseType4RASelected?.name.trim();;
        break;
      default:
        return;
    }

    const confirmDialog = this.showConfirmationDialog(
      "Clear All Items",
      "Removing "+ expertiseTypeName + " will clear all items included in " + groupName +".  Continue?",
      "Yes",
      "No"
    );

    confirmDialog.afterClosed().subscribe((result) => {
      if (result === "ok") {
        switch(groupListName) {
          case "groupinglist1":
            this.expertiseType1RASelected = null;
            break;
          case "groupinglist2":
            this.expertiseType2RASelected = null;
            break;
          case "groupinglist3":
            this.expertiseType3RASelected = null;
            break;
          case "groupinglist4":
            this.expertiseType4RASelected = null;
            break;
        }

        selectedItems.forEach((item) => {
            switch (groupListName) {
              case 'groupinglist1':
                this.rightOption1Click(item.id, item);
                break;
              case 'groupinglist2':
                this.rightOption2Click(item.id, item);
                break;
              case 'groupinglist3':
                this.rightOption3Click(item.id, item);
                break;
              case 'groupinglist4':
                this.rightOption4Click(item.id, item);
                break;
            }
        });        

        this.populateResumeExpertiseGroupsFromDropDownsAndListBoxes();
      } else {
        noButtonClicked = true;
      }

      switch(groupListName) {
        case "groupinglist1":
          this.isSkillGroup1Enabled = this.skillGroup1?.length > 0 ? true: false;
          this.autoTrigger1.closePanel();
          if(noButtonClicked){
            let selMatOption = this.autocomplete1.options.toArray()
                             .find((o) =>
                              o.value.name.trim().toLowerCase() === this.expertiseType1RASelected.name.trim().toLowerCase());
            this.expertiseType1.setValue(selMatOption?.value);
          }
          break;
        case "groupinglist2":
          this.isSkillGroup2Enabled = this.skillGroup2?.length > 0 ? true: false;
          this.autoTrigger2.closePanel();
          if(noButtonClicked){
            let selMatOption = this.autocomplete2.options.toArray()
                            .find((o) =>
                            o.value.name.trim().toLowerCase() === this.expertiseType2RASelected.name.trim().toLowerCase());
            this.expertiseType2.setValue(selMatOption?.value);
          }
          break;
        case "groupinglist3":
          this.isSkillGroup3Enabled = this.skillGroup3?.length > 0 ? true: false;
          this.autoTrigger3.closePanel();
          if(noButtonClicked){
            let selMatOption = this.autocomplete3.options.toArray()
                            .find((o) =>
                            o.value.name.trim().toLowerCase() === this.expertiseType3RASelected.name.trim().toLowerCase());
            this.expertiseType3.setValue(selMatOption?.value);
          }
          break;
        case "groupinglist4":
          this.isSkillGroup4Enabled = this.skillGroup4?.length > 0 ? true: false;
          this.autoTrigger4.closePanel();          
          if(noButtonClicked){
            let selMatOption = this.autocomplete4.options.toArray()
                            .find((o) =>
                            o.value.name.trim().toLowerCase() === this.expertiseType4RASelected.name.trim().toLowerCase());
            this.expertiseType4.setValue(selMatOption?.value);
          }
          break;
        }
        this.store.dispatch(userSkillsActions.resetExpertiseTypeFilterValue());
    });

    this.onRightSideChange.emit(this.resumeExpertiseGroups);
    this.isUserSkillsPageValid ? this.parentForm.markAsDirty() : this.parentForm.markAsPristine();
  }

  private showConfirmationDialog(
    title: string,
    message: string,
    okButtonTitle: string,
    cancelButtonTitle: string = undefined,
    flipButtonColor: boolean = false
  ): any {
    const confirmDialog = new ConfirmDialog();

    confirmDialog.title = title;
    confirmDialog.message = message;
    confirmDialog.okButtonTitle = okButtonTitle;
    confirmDialog.flipButtonColor = flipButtonColor;
    if (cancelButtonTitle) {
      confirmDialog.cancelButtonTitle = cancelButtonTitle;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data: confirmDialog,
      disableClose: true,
      autoFocus: false,
    });

    return dialogRef;
  }

  onFilterExpertiseType1(name: string) {
    const filterValue = name?.toLowerCase();

    this.expertiseTypeOptions1 = of(this.expertiseTypes1.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    ));

    this.store.dispatch(userSkillsActions.setExpertiseTypeFilterValue({ value: name, resetFilterResult: false }));

    this.isSkillGroup1Enabled = name !== '' ? true : false;
    if(name === '') {
      if (this.skillGroup1.length > 0) {
        this.clearSelectedItems('groupinglist1');
      } else {
        this.expertiseType1RASelected = null;
        this.emitRightSideChange(this.resumeExpertiseGroups);
      }
    }
    else {
      this.emitRightSideChange(this.resumeExpertiseGroups);
    }
  }

  onExpertiseTypeSelected1(attribute: RAttribute) {
    this.expertiseType1.setValue(attribute?.name);
    this.expertiseType1RASelected = attribute;
    this.store.dispatch(userSkillsActions.setExpertiseTypeFilterValue({ value: attribute.name, resetFilterResult: true }))

    this.isSkillGroup1Enabled = attribute?.id !== 0 ? true : false;
    let expertiseType2Value = this.expertiseType2.value;
    let expertiseType3Value = this.expertiseType3.value;
    let expertiseType4Value = this.expertiseType4.value;

    if(this.skillGroup1?.length > 0) {
      this.populateResumeExpertiseGroupsFromDropDownsAndListBoxes();      
    }

    this.onRightSideChange.emit(this.resumeExpertiseGroups);
    this.isUserSkillsPageValid ? this.parentForm.markAsDirty() : this.parentForm.markAsPristine();

    // Compare with $event.option.value
    return (expertiseType2Value === attribute ||
            expertiseType3Value === attribute ||
            expertiseType4Value === attribute);
  }

  onFilterExpertiseType2(name: string) {
    const filterValue = name?.toLowerCase();

    this.expertiseTypeOptions2 = of(this.expertiseTypes2.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    ));

    this.store.dispatch(userSkillsActions.setExpertiseTypeFilterValue({ value: name, resetFilterResult: false }));

    this.isSkillGroup2Enabled = name !== '' ? true : false;
    if(name === '') {
      if (this.skillGroup2.length > 0) {
        this.clearSelectedItems('groupinglist2');
      } else {
        this.expertiseType2RASelected = null;
        this.emitRightSideChange(this.resumeExpertiseGroups);
      }
    }
    else {
      this.emitRightSideChange(this.resumeExpertiseGroups);
    }
  }

  onExpertiseTypeSelected2(attribute: RAttribute) {
    this.expertiseType2.setValue(attribute.name);
    this.expertiseType2RASelected = attribute;
    this.store.dispatch(userSkillsActions.setExpertiseTypeFilterValue({ value: attribute.name, resetFilterResult: true }))

    this.isSkillGroup2Enabled = attribute?.id !== 0 ? true : false;
    let expertiseType1Value = this.expertiseType1.value;
    let expertiseType3Value = this.expertiseType3.value;
    let expertiseType4Value = this.expertiseType4.value;

    if(this.skillGroup2?.length > 0) {
      this.populateResumeExpertiseGroupsFromDropDownsAndListBoxes();
    }

    this.onRightSideChange.emit(this.resumeExpertiseGroups);
    this.isUserSkillsPageValid ? this.parentForm.markAsDirty() : this.parentForm.markAsPristine();

    // Compare with $event.option.value
    return (expertiseType1Value === attribute ||
            expertiseType3Value === attribute ||
            expertiseType4Value === attribute);
  }

  onFilterExpertiseType3(name: string) {
    const filterValue = name?.toLowerCase();

    this.expertiseTypeOptions3 = of(this.expertiseTypes3.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    ));

    this.store.dispatch(userSkillsActions.setExpertiseTypeFilterValue({ value: name, resetFilterResult: false }));
    this.isSkillGroup3Enabled = name !== '' ? true : false;
    if(name === '') {
      if (this.skillGroup3.length > 0) {
        this.clearSelectedItems('groupinglist3');
      } else {
        this.expertiseType3RASelected = null;
        this.emitRightSideChange(this.resumeExpertiseGroups);
      }
    }
    else {
      this.emitRightSideChange(this.resumeExpertiseGroups);
    }
  }

  onExpertiseTypeSelected3(attribute: RAttribute) {
    this.expertiseType3.setValue(attribute.name);
    this.expertiseType3RASelected = attribute;
    this.store.dispatch(userSkillsActions.setExpertiseTypeFilterValue({ value: attribute.name, resetFilterResult: true }))

    this.isSkillGroup3Enabled = attribute?.id !== 0 ? true : false;
    let expertiseType1Value = this.expertiseType1.value;
    let expertiseType2Value = this.expertiseType2.value;
    let expertiseType4Value = this.expertiseType4.value;

    if(this.skillGroup3?.length > 0) {
      this.populateResumeExpertiseGroupsFromDropDownsAndListBoxes();
    }

    this.onRightSideChange.emit(this.resumeExpertiseGroups);
    this.isUserSkillsPageValid ? this.parentForm.markAsDirty() : this.parentForm.markAsPristine();

    // Compare with $event.option.value
    return (expertiseType1Value === attribute ||
            expertiseType2Value === attribute ||
            expertiseType4Value === attribute);
  }

  onFilterExpertiseType4(name: string) {
    const filterValue = name?.toLowerCase();

    this.expertiseTypeOptions4 = of(this.expertiseTypes4.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    ));

    this.store.dispatch(userSkillsActions.setExpertiseTypeFilterValue({ value: name, resetFilterResult: false }));

    this.isSkillGroup4Enabled = name !== '' ? true : false;
    if(name === '') {
      if (this.skillGroup4.length > 0) {
        this.clearSelectedItems('groupinglist4');
      } else {
        this.expertiseType4RASelected = null;
        this.emitRightSideChange(this.resumeExpertiseGroups);
      }
    }
    else {
      this.emitRightSideChange(this.resumeExpertiseGroups);
    }
  }

  onExpertiseTypeSelected4(attribute: RAttribute) {
    this.expertiseType4.setValue(attribute.name);
    this.expertiseType4RASelected = attribute;
    this.store.dispatch(userSkillsActions.setExpertiseTypeFilterValue({ value: attribute.name, resetFilterResult: true }))

    this.isSkillGroup4Enabled = attribute?.id !== 0 ? true : false;
    let expertiseType1Value = this.expertiseType1.value;
    let expertiseType2Value = this.expertiseType2.value;
    let expertiseType3Value = this.expertiseType3.value;

    if(this.skillGroup4?.length > 0) {
      this.populateResumeExpertiseGroupsFromDropDownsAndListBoxes();
    }

    this.onRightSideChange.emit(this.resumeExpertiseGroups);
    this.isUserSkillsPageValid ? this.parentForm.markAsDirty() : this.parentForm.markAsPristine();

    // Compare with $event.option.value
    return (expertiseType1Value === attribute ||
            expertiseType2Value === attribute ||
            expertiseType3Value === attribute);
  }

  optionDisplay = (option?: RAttribute): string | undefined => {
    return option?.name;
  };

  resetFilter() {
    this.isResetting = true;
    setTimeout(() => {
      this.store.dispatch(userSkillsActions.resetExpertiseTypeFilterValue());
      this.expertiseType1.setValue({ name: '', id: 0, value: '' });
      this.isResetting = false;
    }, 500);
  }

  isOptionGroup1Disabled = (option?: RAttribute): boolean | undefined => {
    const expertiseType2Value = this.expertiseType2.value as RAttribute;
    const expertiseType3Value = this.expertiseType3.value as RAttribute;
    const expertiseType4Value = this.expertiseType4.value as RAttribute;

    // Compare with option.id
    return ((expertiseType2Value !== null && expertiseType2Value?.id === option?.id) ||
            (expertiseType3Value !== null && expertiseType3Value?.id === option?.id) ||
            (expertiseType4Value !== null && expertiseType4Value?.id === option?.id));
  };

  isOptionGroup2Disabled = (option?: RAttribute): boolean | undefined => {
    const expertiseType1Value = this.expertiseType1.value as RAttribute;
    const expertiseType3Value = this.expertiseType3.value as RAttribute;
    const expertiseType4Value = this.expertiseType4.value as RAttribute;

    // Compare with option.id
    return ((expertiseType1Value !== null && expertiseType1Value?.id === option?.id) ||
            (expertiseType3Value !== null && expertiseType3Value?.id === option?.id) ||
            (expertiseType4Value !== null && expertiseType4Value?.id === option?.id));
  };

  isOptionGroup3Disabled = (option?: RAttribute): boolean | undefined => {
    const expertiseType1Value = this.expertiseType1.value as RAttribute;
    const expertiseType2Value = this.expertiseType2.value as RAttribute;
    const expertiseType4Value = this.expertiseType4.value as RAttribute;

    // Compare with option.id
    return ((expertiseType1Value !== null && expertiseType1Value?.id === option?.id) ||
            (expertiseType2Value !== null && expertiseType2Value?.id === option?.id) ||
            (expertiseType4Value !== null && expertiseType4Value?.id === option?.id));
  };

  isOptionGroup4Disabled = (option?: RAttribute): boolean | undefined => {
    const expertiseType1Value = this.expertiseType1.value as RAttribute;
    const expertiseType2Value = this.expertiseType2.value as RAttribute;
    const expertiseType3Value = this.expertiseType3.value as RAttribute;

    // Compare with option.id
    return ((expertiseType1Value !== null && expertiseType1Value?.id === option?.id) ||
            (expertiseType2Value !== null && expertiseType2Value?.id === option?.id) ||
            (expertiseType3Value !== null && expertiseType3Value?.id === option?.id));
  };

  getMapDropDown(previousIndex: number, previousId: string): MultiSelectSkillsOption<T> {
    var newItem;
    switch (previousId) {
      case "leftSideSkills":
        newItem = this.leftSide[previousIndex];
        break;
      case "groupinglist1":
        newItem = this.skillGroup1[previousIndex];
        break;
      case "groupinglist2":
        newItem = this.skillGroup2[previousIndex];
        break;
      case "groupinglist3":
        newItem = this.skillGroup3[previousIndex];
        break;
      case "groupinglist4":
        newItem = this.skillGroup4[previousIndex];
        break;
      default:
        newItem = null;
        break;
    }
    return newItem;
  }

  deleteFromMapDropDown(id: string, value: number): void {
    switch (id) {
      case "leftSideSkills":
        this._leftList.delete(value);
        break;
      case "groupinglist1":
        this._rightGroup1.delete(value);
        break;
      case "groupinglist2":
        this._rightGroup2.delete(value);
        break;
      case "groupinglist3":
        this._rightGroup3.delete(value);
        break;
      case "groupinglist4":
        this._rightGroup4.delete(value);
        break;
      default:
        break;
    }
  }

  dropGroup1(event: CdkDragDrop<string[]>) {
    if(event.previousContainer === event.container) {
      moveItemInArray(this.skillGroup1, event.previousIndex, event.currentIndex);
      this.populateResumeExpertiseGroupsFromDropDownsAndListBoxes();

      this.onRightSideChange.emit(this.resumeExpertiseGroups);
      this.isUserSkillsPageValid ? this.parentForm.markAsDirty() : this.parentForm.markAsPristine();
      return;
    }

    let newItem = this.getMapDropDown(event.previousIndex, event.previousContainer.id);
    this._rightGroup1.set(newItem.value, newItem);

    transferArrayItem(
      event.previousContainer.data,
      event.container.data,
      event.previousIndex,
      event.currentIndex,
    );

    this.deleteFromMapDropDown(event.previousContainer.id, newItem.value);

    this.populateResumeExpertiseGroupsFromDropDownsAndListBoxes();

    this.onRightSideChange.emit(this.resumeExpertiseGroups);
    this.isUserSkillsPageValid ? this.parentForm.markAsDirty() : this.parentForm.markAsPristine();
  }

  rightOption1Click(index: number, selected: DropDown<T>): void {
    if (!this.isReadOnly) {
      this.clearErrorMessage();

      this._leftList.set(selected.value, selected);
      this._rightGroup1.delete(selected.value);
      this.skillGroup1 = this.skillGroup1.filter((item) => item.value !== selected.value);

      this.populateLeftSide();
      this.populateResumeExpertiseGroupsFromDropDownsAndListBoxes();

      this.onRightSideChange.emit(this.resumeExpertiseGroups);
      this.isUserSkillsPageValid ? this.parentForm.markAsDirty() : this.parentForm.markAsPristine();
    }
  }

  dropGroup2(event: CdkDragDrop<string[]>) {
    if(event.previousContainer === event.container) {
      moveItemInArray(this.skillGroup2, event.previousIndex, event.currentIndex);
      this.populateResumeExpertiseGroupsFromDropDownsAndListBoxes();

      this.onRightSideChange.emit(this.resumeExpertiseGroups);
      this.isUserSkillsPageValid ? this.parentForm.markAsDirty() : this.parentForm.markAsPristine();
      return;
    }

    let newItem = this.getMapDropDown(event.previousIndex, event.previousContainer.id);
    this._rightGroup2.set(newItem.value, newItem);

    transferArrayItem(
      event.previousContainer.data,
      event.container.data,
      event.previousIndex,
      event.currentIndex,
    );

    this.deleteFromMapDropDown(event.previousContainer.id, newItem.value);

    this.populateResumeExpertiseGroupsFromDropDownsAndListBoxes();

    this.onRightSideChange.emit(this.resumeExpertiseGroups);
    this.isUserSkillsPageValid ? this.parentForm.markAsDirty() : this.parentForm.markAsPristine();
  }

  rightOption2Click(index: number, selected: DropDown<T>): void {
    if (!this.isReadOnly) {
      this.clearErrorMessage();

      this._leftList.set(selected.value, selected);
      this._rightGroup2.delete(selected.value);
      this.skillGroup2 = this.skillGroup2.filter((item) => item.value !== selected.value);

      this.populateLeftSide();
      this.populateResumeExpertiseGroupsFromDropDownsAndListBoxes();

      this.onRightSideChange.emit(this.resumeExpertiseGroups);
      this.isUserSkillsPageValid ? this.parentForm.markAsDirty() : this.parentForm.markAsPristine();
    }
  }

  dropGroup3(event: CdkDragDrop<string[]>) {
    if(event.previousContainer === event.container) {
      moveItemInArray(this.skillGroup3, event.previousIndex, event.currentIndex);
      this.populateResumeExpertiseGroupsFromDropDownsAndListBoxes();

      this.onRightSideChange.emit(this.resumeExpertiseGroups);
      this.isUserSkillsPageValid ? this.parentForm.markAsDirty() : this.parentForm.markAsPristine();
      return;
    }

    let newItem = this.getMapDropDown(event.previousIndex, event.previousContainer.id);
    this._rightGroup3.set(newItem.value, newItem);

    transferArrayItem(
      event.previousContainer.data,
      event.container.data,
      event.previousIndex,
      event.currentIndex,
    );

    this.deleteFromMapDropDown(event.previousContainer.id, newItem.value);

    this.populateResumeExpertiseGroupsFromDropDownsAndListBoxes();

    this.onRightSideChange.emit(this.resumeExpertiseGroups);
    this.isUserSkillsPageValid ? this.parentForm.markAsDirty() : this.parentForm.markAsPristine();
  }

  rightOption3Click(index: number, selected: DropDown<T>): void {
    if (!this.isReadOnly) {
      this.clearErrorMessage();

      this._leftList.set(selected.value, selected);
      this._rightGroup3.delete(selected.value);
      this.skillGroup3 = this.skillGroup3.filter((item) => item.value !== selected.value);

      this.populateLeftSide();
      this.populateResumeExpertiseGroupsFromDropDownsAndListBoxes();

      this.onRightSideChange.emit(this.resumeExpertiseGroups);
      this.isUserSkillsPageValid ? this.parentForm.markAsDirty() : this.parentForm.markAsPristine();
    }
  }

  dropGroup4(event: CdkDragDrop<string[]>) {
    if(event.previousContainer === event.container) {
      moveItemInArray(this.skillGroup4, event.previousIndex, event.currentIndex);
      this.populateResumeExpertiseGroupsFromDropDownsAndListBoxes();

      this.onRightSideChange.emit(this.resumeExpertiseGroups);
      this.isUserSkillsPageValid ? this.parentForm.markAsDirty() : this.parentForm.markAsPristine();
      return;
    }

    let newItem = this.getMapDropDown(event.previousIndex, event.previousContainer.id);
    this._rightGroup4.set(newItem.value, newItem);

    transferArrayItem(
      event.previousContainer.data,
      event.container.data,
      event.previousIndex,
      event.currentIndex,
    );

    this.deleteFromMapDropDown(event.previousContainer.id, newItem.value);

    this.populateResumeExpertiseGroupsFromDropDownsAndListBoxes();

    this.onRightSideChange.emit(this.resumeExpertiseGroups);
    this.isUserSkillsPageValid ? this.parentForm.markAsDirty() : this.parentForm.markAsPristine();
  }

  rightOption4Click(index: number, selected: DropDown<T>): void {
    if (!this.isReadOnly) {
      this.clearErrorMessage();

      this._leftList.set(selected.value, selected);
      this._rightGroup4.delete(selected.value);
      this.skillGroup4 = this.skillGroup4.filter((item) => item.value !== selected.value);

      this.populateLeftSide();
      this.populateResumeExpertiseGroupsFromDropDownsAndListBoxes();

      this.onRightSideChange.emit(this.resumeExpertiseGroups);
      this.isUserSkillsPageValid ? this.parentForm.markAsDirty() : this.parentForm.markAsPristine();
    }
  }

  getInputControlValue(inputFormControl: FormControl<string | any>) {
    if(typeof inputFormControl.value === 'object')
      return this.optionDisplay(inputFormControl.value);

    return inputFormControl.value;
  }

  emitRightSideChange(resumeExpertiseGroups: RResumeExpertiseGroup[]) {
    this.onRightSideChange.emit(resumeExpertiseGroups);
    this.isUserSkillsPageValid ? this.parentForm.markAsDirty() : this.parentForm.markAsPristine();
  }
  
  addExpertiseType(expertiseType: RAttribute) {
    userSkillsActions.addUserSkillExpertiseType({ expertiseType });
    return of(expertiseType);
  }

  saveExpertiseType(newExpertiseType: RAttribute, skillGrouping: string) {
    const userId = this.userId;
    this.store.dispatch(userSkillsActions.saveGroupExpertiseType({
      newExpertiseType,
      userId
    }));
    this.store.dispatch(userSkillsActions.setExpertiseTypeFilterValue({ value: newExpertiseType.name, resetFilterResult: true }))
    return true;
  }

  addOtherExpertise(attribute: RAttribute,  skillGrouping: string) {
    attribute.value = null;
    attribute.userId = this.userId;
    attribute.isOther = false;
    this.store.dispatch(userSkillsActions.saveGroupExpertiseType({
      newExpertiseType: attribute,
      userId: this.userId
    }));
   console.log("skill", skillGrouping);
   console.log("attribute", attribute);
   console.log(this.expertiseTypeOptions);
  
    let lastSkillGrouping = skillGrouping;
    let attributeName = attribute.name;
    this.expertiseType$.pipe(
      take(2)
    ).subscribe((newExpertiseType) => {
      
      if (newExpertiseType) {
        var newAttribute = newExpertiseType.find((option) => option?.name === attributeName);
        console.log("newAttribute", newAttribute);
        if (newAttribute) {
          this.onExpertiseTypeSelected(newAttribute, lastSkillGrouping);
        }
      }
    });
    
  }
  
  onExpertiseTypeSelected(attribute: RAttribute, skillGrouping: string){

    if(skillGrouping =='group1'){
      this.onExpertiseTypeSelected1(attribute);
      } else if(skillGrouping =='group2'){  
      this.onExpertiseTypeSelected2(attribute);
      } else if(skillGrouping =='group3'){
      this.onExpertiseTypeSelected3(attribute);
      } else if(skillGrouping =='group4'){  
      this.onExpertiseTypeSelected4(attribute);
      } 
  }

  disableOptions(group: string){
    let expertiseTypeOptionsPickList: RAttribute[] = this.expertiseTypeOptions != null ? JSON.parse(JSON.stringify(this.expertiseTypeOptions)) : [];
    console.log(expertiseTypeOptionsPickList);
    if(group == 'group1'){
      const optionSelected2 = expertiseTypeOptionsPickList.find((option) => option.id === this.expertiseType2RASelected?.id);
      if (optionSelected2) {
        optionSelected2.id = -1;
      }

      const optionSelected3 = expertiseTypeOptionsPickList.find((option) => option.id === this.expertiseType3RASelected?.id);
      if (optionSelected3) {
        optionSelected3.id = -1;
      }

      const optionSelected4 = expertiseTypeOptionsPickList.find((option) => option.id === this.expertiseType4RASelected?.id);
      if (optionSelected4) {
        optionSelected4.id = -1;
      }
    } else if(group =='group2'){  
        const optionSelected1 = expertiseTypeOptionsPickList.find((option) => option.id === this.expertiseType1RASelected?.id);
        if (optionSelected1) {
          optionSelected1.id = -1;
        }

        const optionSelected3 = expertiseTypeOptionsPickList.find((option) => option.id === this.expertiseType3RASelected?.id);
        if (optionSelected3) {
          optionSelected3.id = -1;
        }

        const optionSelected4 = expertiseTypeOptionsPickList.find((option) => option.id === this.expertiseType4RASelected?.id);
        if (optionSelected4) {
          optionSelected4.id = -1;
        }
      } else if(group =='group3'){
        const optionSelected1 = expertiseTypeOptionsPickList.find((option) => option.id === this.expertiseType1RASelected?.id);
        if (optionSelected1) {
          optionSelected1.id = -1;
        }

        const optionSelected2 = expertiseTypeOptionsPickList.find((option) => option.id === this.expertiseType2RASelected?.id);
        if (optionSelected2) {
          optionSelected2.id = -1;
        }

        const optionSelected4 = expertiseTypeOptionsPickList.find((option) => option.id === this.expertiseType4RASelected?.id);
        if (optionSelected4) {
          optionSelected4.id = -1;
        }
      } else if(group =='group4'){  
        const optionSelected1 = expertiseTypeOptionsPickList.find((option) => option.id === this.expertiseType1RASelected?.id);
        if (optionSelected1) {
          optionSelected1.id = -1;
        }

        const optionSelected2 = expertiseTypeOptionsPickList.find((option) => option.id === this.expertiseType2RASelected?.id);
        if (optionSelected2) {
          optionSelected2.id = -1;
        }

        const optionSelected3 = expertiseTypeOptionsPickList.find((option) => option.id === this.expertiseType3RASelected?.id);
        if (optionSelected3) {
          optionSelected3.id = -1;
        }
      } 
    return expertiseTypeOptionsPickList;
  }
  displaySkillGroupingPickList(event: UIEvent, group: string) {
    if (event) {
      event.preventDefault();
    }
    
      const confirmDialog = new PickList();
      this.cdr.detectChanges();
      confirmDialog.message = "";
      confirmDialog.okButtonTitle = "";
      confirmDialog.cancelButtonTitle = "Cancel";
      confirmDialog.listType = "Skill Group";
      confirmDialog.pickListData = this.disableOptions(group);

      const dialogRef = this.dialog.open(PickListComponent, {
        width: "700px",
        data: confirmDialog,
        disableClose: false,
      });

      dialogRef.afterClosed().subscribe((closeData) => {
        if (closeData?.result) {
          const expertiseSelected: RAttribute = closeData.pickListItem;

          if (expertiseSelected.id == 0) {
            this.addOtherExpertise(expertiseSelected, group);
          }
          else {
            this.onExpertiseTypeSelected(expertiseSelected, group);
          } 
        }
      });
    
  } 

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
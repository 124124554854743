import {
  UntypedFormControl,
  Validators,
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormArray,
} from "@angular/forms";
import { SnackBarService } from "./../../common/services/snackbar.service";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Input,
  Output,
  AfterViewInit,
} from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { pairwise, startWith } from "rxjs/operators";
import { fadeInUp400ms } from "src/@vex/animations/fade-in-up.animation";
import { stagger60ms } from "src/@vex/animations/stagger.animation";
import * as _moment from "moment";
import { MatDialog } from "@angular/material/dialog";
import { default as _rollupMoment } from "moment";
import { AuthService } from "src/app/common/services/auth.service";

import {
  ConfirmDialog,
  ConfirmationDialogComponent,
} from "src/app/shared/confirmation-dialog/confirmation-dialog.component";
import { PickList, PickListComponent } from "src/app/shared/pick-list/pick-list.component";
import { RAttribute, Skill } from "../../common/data-models/commonDataModels";
import { ProfileSummary } from "../../common/data-models/ProfileSummary";
import { SkillSummary } from "../../common/data-models/SkillSummary";
import { RSkill } from "../../common/data-models/RSkill";
import {
  ResumeService,
  ResumeStatusTypes,
} from "../../common/services/resume.service";
import { RAssociation } from "../../common/data-models/RAssociation";
import { EducationModel } from "../../common/data-models/EducationModel";
import { RProject } from "../../common/data-models/project";
import { Resume } from "src/app/common/data-models/Resume";
import { Router } from "@angular/router";
import { WorkflowService } from "src/app/common/services/workflow.service";
import { ReviewCommentsModalComponent } from "../../review-comments-modal/review-comments-modal.component";
import { ResumeOutputModal } from "../resume-output-modal/resume-output-modal";
import {
  BuildResumeFormUtilService,
  BuildResumeState,
} from "../build-resume-form-state.service";
import { NavigationService } from "../../../@vex/services/navigation.service";
import { AltOnlinerDefault } from "../../common/data-models/AltOnlinerDefault";
import { GlobalConstants } from "src/app/shared/global-constants";
import icInfoCircle from "@iconify-icons/uil/info-circle";
import { MultiSelectOption } from "./multi-select-card/multi-select-card.component";
import { MultiSelectSkillsOption } from "./multi-select-skills-card/multi-select-skills-card.component";
import { Education } from "src/app/common/data-models/Education";
import { formatDate } from "@angular/common";
import { CommonService, DropDown } from "src/app/common/services/common.service";
import { timeStamp } from "console";
import { throwToolbarMixedModesError } from "@angular/material/toolbar";
import { RResumeExpertiseGroup } from "src/app/common/data-models/RResumeExpertiseGroup";

@Component({
  selector: "obs-build-resume-card",
  templateUrl: "./build-resume-card.component.html",
  styleUrls: ["./build-resume-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [stagger60ms, fadeInUp400ms],
})
export class BuildResumeCardComponent implements OnInit, AfterViewInit {
  public resumeForm: UntypedFormGroup;
  @Input() resume: Resume;
  @Input() refResumeId: number;
  @Input() fullyLoaded: boolean;
  @Input() cloneInProgress: boolean;
  @Input() formsListState: BuildResumeState;
  @Input() spins: RAttribute[];
  @Input() profileSummaries: DropDown<ProfileSummary>[];
  @Input() skillSummaries: DropDown<SkillSummary>[];
  @Input() skills: DropDown<RSkill>[];
  @Input() userSkills: DropDown<RSkill>[];
  @Input() resumeExpertiseGroups: RResumeExpertiseGroup[];
  @Input() associations: DropDown<RAssociation>[];
  @Input() educations: DropDown<EducationModel>[];
  @Input() projects: DropDown<RProject>[];
  @Input() cloneResumeStatus: string;
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() markFullyLoaded: EventEmitter<number> = new EventEmitter();
  @Output() markCloneResume: EventEmitter<Resume> = new EventEmitter();
  @Output() markNewResumeCloning: EventEmitter<boolean> = new EventEmitter();
  @Output() refreshList: EventEmitter<any> = new EventEmitter();
  @Output() open: EventEmitter<any> = new EventEmitter();

  public model: Resume;
  public panelOpenState = false;
  public isDirty = false;
  public showPreview = false;
  public dateFormat = GlobalConstants.FULL_DATE_FORMAT;
  private userId: string;
  private selected = 0;
  private isSlideChecked: boolean = this.navigationService.getIsSlideChecked();
  private altOnlinerDefault: AltOnlinerDefault =
    this.navigationService.getAltOnlinerDefault();

  isLoading: boolean = true;
  isLoading$: BehaviorSubject<boolean>;

  isDeleting: boolean = false;

  isSubmitting: boolean = false;
  isSubmitting$: BehaviorSubject<boolean>;
  isRequesting: boolean = false;
  isRequesting$: BehaviorSubject<boolean>;
  disableAnimation = true;
  skillsMaxExpertiseType = 4;
  isAfterSaving: boolean = false;
  icInfoCircle = icInfoCircle;
  refreshDropDownList: boolean = false;
  spin: UntypedFormControl;

  get isSnapshot(): boolean {
    return this.resume?.isSnapshot;
  }

  get spinFormControl(): UntypedFormControl {
    return this.spin;
  }

  get accreditationFormControl() {
    return this.resumeForm.get("accreditation") as UntypedFormControl;
  }

  get profileSummaryFormControl() {
    return this.resumeForm.get("profileSummary") as UntypedFormControl;
  }

  get otherSpinAvailable(): boolean {
    return this.resumeForm.get("otherSpin") !== null;
  }

  get skillSummaryFormArray() {
    return this.resumeForm.get("skillSummaries") as UntypedFormArray;
  }

  get skillFormArray() {
    return this.resumeForm.get("skills") as UntypedFormArray;
  }

  get userSkillFormArray() {
    return this.resumeForm.get("userSkills") as UntypedFormArray;
  }
  
  get resumeExpertiseGroupFormArray() {
    return this.resumeForm.get("resumeExpertiseGroups") as UntypedFormArray;
  }

  get educationFormArray() {
    return this.resumeForm.get("educations") as UntypedFormArray;
  }

  get projectFormArray() {
    return this.resumeForm.get("projects") as UntypedFormArray;
  }

  get associationFormArray() {
    return this.resumeForm.get("associations") as UntypedFormArray;
  }

  get saveDisabled() {
    return (
      this.pageIsNotReady ||
      !this.resumeForm.dirty ||
      !this.isSpinFormControlValid() ||
      !this.isAccreditationFormControlValid() ||
      !this.isUserSkillFormArrayValid() ||
      !this.noEmptyInResume ||
      this.errorInResume
    );
  }

  get submitDisplayed() {
    return !this.isSlideChecked && this.saveDisabled;
  }

  get submitDisabled() {
    return (
      this.pageIsNotReady ||
      !this.resumeIsCompleted ||
      this.resume.id < 0 ||
      !this.saveDisabled
    );
  }

  get submitAndSaveDisabled() {
    return (
      this.pageIsNotReady || 
      !this.resumeIsCompleted || 
      !this.resumeForm.dirty
    );
  }

  get submitAndSaveDisplayed() {
    return !this.isSlideChecked && !this.saveDisabled;
  }

  get pageIsNotReady() {
    return (
      this.isLoading ||
      this.isSubmitting ||
      this.isRequesting ||
      this.isReadOnly()
    );
  }

  get resumeIsCompleted() {
    return (
      !this.resumeForm.invalid &&
      this.isSpinFormControlValid() &&
      this.isProfileFormControlValid() &&
      this.isAccreditationFormControlValid() &&
      this.isSkillSummaryFormArrayValid() &&
      this.isUserSkillFormArrayValid() &&
      this.isEducationFormArrayValid() &&
      this.isProjectFormArrayValid() &&
      this.isAssociationFormArrayValid()
    );
  }

  get noEmptyInResume() {
    return (
      this.skillSummaryFormArray.value.every((ss: number) => ss > 0) &&
      this.userSkillFormArray.value.every((ss: number) => ss > 0) &&
      this.educationFormArray.value.every((ss: number) => ss > 0) &&
      this.projectFormArray.value.every((ss: number) => ss > 0) &&
      this.associationFormArray.value.every((ss: number) => ss > 0)
    );
  }

  get errorInResume() {
    return (
      this.skillSummaryFormArray?.invalid ||
      this.userSkillFormArray?.invalid ||
      this.educationFormArray?.invalid ||
      this.projectFormArray?.invalid ||
      this.associationFormArray?.invalid
    );
  }

  get isOldSnapshotResume() {
    return (this.resume?.resumeExpertiseGroups?.length < 1 && 
           this.resume?.skills?.length > 0) &&
           this.resume?.isSnapshot === true;
  }

  public constructor(
    private authService: AuthService,
    public dialog: MatDialog,
    private navigationService: NavigationService,
    private cdr: ChangeDetectorRef,
    private snackBarService: SnackBarService,
    private fb: UntypedFormBuilder,
    private resumeService: ResumeService,
    private router: Router,
    private workflowService: WorkflowService,
    private buildResumeStateService: BuildResumeFormUtilService,
    private commonService: CommonService
  ) { }

  ngAfterViewInit(): void {
    //  required to prevent the mat-expansion-panel from appearing as expanded during page load
    setTimeout(() => (this.disableAnimation = false));
  }

  public async ngOnInit(): Promise<void> {
    this.userId = this.authService.getUserId();
    this.spin = new UntypedFormControl();

    this.initForm();

    this.isLoading$ = new BehaviorSubject<boolean>(this.isLoading);
    this.isSubmitting$ = new BehaviorSubject<boolean>(this.isSubmitting);
    this.isRequesting$ = new BehaviorSubject<boolean>(this.isRequesting);

    if (this.isReadOnly()) {
      this.disableForm();
    }

    if (this.resume != null && this.resume.id < 0) {
      this.panelOpenState = true;

      //when cloning, the refResume will contain the cloned resume id,
      //so we need to fully load it
      if (this.cloneInProgress && this.refResumeId > -1) {
        //condition for when cloning

        await this.loadResume(this.refResumeId);
        this.resumeForm.markAsDirty();
      } else {
        //new resumes
        this.isLoading$.next((this.isLoading = false));

        setTimeout(() => {
          this.markFullyLoaded.emit(this.resume.id);
        });
      }
    }

    // this.cdr.detectChanges();
  }

  private initForm(): void {
    const skillSummaries =
      this.resume?.skillSummaries?.map((r) => new UntypedFormControl(r.id)) || [];
    const skills =
      this.resume?.skills?.map((r) => new UntypedFormControl(r.skillId)) || [];
    const userSkills =
      this.resume?.skills?.map((r) => new UntypedFormControl(r.skillId)) || [];   
    const resumeExpertiseGroups = 
      this.resume?.resumeExpertiseGroups?.map((r) => new UntypedFormControl(r.skillId)) || [];   
    const educations =
      this.resume?.educations?.map((r) => new UntypedFormControl(r.educationId)) || [];
    const projects =
      this.resume?.projects?.map((r) => new UntypedFormControl(r.id)) || [];
    const associations =
      this.resume?.associations?.map((r) => new UntypedFormControl(r.assocId)) || [];
    this.resumeForm = this.fb.group({
      Spin: new UntypedFormControl(
        {
          value: this.getSpinFromList() || "",
        },
        Validators.required,
      ),
      accreditation: new UntypedFormControl(this.resume.accreditation),
      profileSummary: new UntypedFormControl(
        this.resume.profileSummaryId,
        Validators.required
      ),
      isClientHidden: new UntypedFormControl(
        this.resume.isClientHidden,
        Validators.required
      ),
      isProfessionalResume: new UntypedFormControl(
        this.resume.isProfessionalResume,
        Validators.required
      ),
      skillSummaries: new UntypedFormArray(skillSummaries),
      skills: new UntypedFormArray(skills),
      userSkills: new UntypedFormArray(userSkills),
      resumeExpertiseGroups: new UntypedFormArray(resumeExpertiseGroups),
      educations: new UntypedFormArray(educations),
      projects: new UntypedFormArray(projects),
      associations: new UntypedFormArray(associations),
      isProjectMostRecent: new UntypedFormControl(
        this.resume.isProjectMostRecent,
        Validators.required
      ),
    });

    if (this.resume.spins?.isOther) {
      this.resumeForm.addControl(
        "otherSpin",
        new UntypedFormControl(this.resume.spins.name, Validators.required)
      );
    }

    this.resumeForm.valueChanges.subscribe(() => {
      if (
        this.resumeForm.dirty &&
        this.formsListState === BuildResumeState.isDirty
      ) {
        return;
      }

      this.buildResumeStateService.setFormsState(
        this.resumeForm.dirty
          ? BuildResumeState.isDirty
          : BuildResumeState.pristine,
        null
      );
    });

    this.attachValidationForErrorCleaningObservers();

    setTimeout(() => {
      this.resumeForm.updateValueAndValidity();
    });
  }

  private initArrayList(): void {
    if (this.isReadOnly()) {
      this.skillSummaries = this.commonService.loadSkillSummariesDropDown(this.resume.skillSummaries);
      this.skills = this.commonService.loadSkillsDropDown(this.resume.skills);
      this.userSkills = this.commonService.loadUserSkillsDropDown(this.resume.skills);
      this.resumeExpertiseGroups = this.resume.resumeExpertiseGroups;
      this.educations = this.commonService.loadEducationsDropDown(this.resume.educations);
      this.associations = this.commonService.loadAssociationsDropDown(this.resume.associations);
      this.projects = this.commonService.loadProjectsDropDown(this.resume.projects);
    }
  }

  public isKeyAllowedOnSpinField(event: KeyboardEvent): boolean {
    //Doing something special here. If user clicks shift followed by an alphanumeric char, we need to return false.
    //But if user has only clicked shift (key == 'Shift') then they haven't clicked another key yet so it's allowed.
    //Then if they click Tab while holding Shift, it is allowed.
    return (
      event.key == "Tab" ||
      event.ctrlKey ||
      event.altKey ||
      event.key == "Shift"
    );
  }

  private attachValidationForErrorCleaningObservers() {
    this.observeDefaultFormArrayDataChangeForErrorCleaning(
      this.skillSummaryFormArray
    );
    this.observeDefaultFormArrayDataChangeForErrorCleaning(this.userSkillFormArray);
    this.observeDefaultFormArrayDataChangeForErrorCleaning(
      this.educationFormArray
    );
    this.observeDefaultFormArrayDataChangeForErrorCleaning(
      this.projectFormArray
    );
    this.observeDefaultFormArrayDataChangeForErrorCleaning(
      this.associationFormArray
    );

    this.observeSkillExpertiseFormArrayDataChangeForErrorCleaning();
  }

  public onAccreditationChange({
    target: { value },
  }: {
    target: { value: string };
  }) {
    this.resume.accreditation = value;
  }

  public onProfileSummaryChange({ value }: { value: number }) {
    this.resume.profileSummaryId = value;

    this.resume.profileSummaries = [
      this.profileSummaries.filter(
        (x) => x.id === this.resume.profileSummaryId
      )[0].element,
    ];
  }

  public onSpinChange({ value }: { value: number }) {
    this.resume.spinsId = value;
    this.resume.spins = this.spins.filter((s) => s.id === value)[0];

    if (value === 0) {
      this.resumeForm.addControl(
        "otherSpin",
        new UntypedFormControl("", Validators.required)
      );
    } else {
      this.resumeForm.removeControl("otherSpin");
    }
  }

  displaySpinPickList(event: UIEvent) {
    if (event) {
      event.preventDefault();
    }

    if (this.resumeForm.enabled) {
      const confirmDialog = new PickList();
      this.cdr.detectChanges();

      confirmDialog.message = "";
      confirmDialog.okButtonTitle = "";
      confirmDialog.cancelButtonTitle = "Cancel";
      confirmDialog.listType = "Spins";
      confirmDialog.pickListData = this.spins;

      const dialogRef = this.dialog.open(PickListComponent, {
        width: "700px",
        data: confirmDialog,
        disableClose: false,
      });

      dialogRef.afterClosed().subscribe((closeData) => {
        if (closeData?.result) {
          const spinSelected: RAttribute = closeData.pickListItem;

          if (spinSelected.id == 0) {
            this.addOtherSpin(spinSelected);
          }
          else {
            this.resume.spins = spinSelected;
            this.resume.spinsId = spinSelected.id;
          }
          this.spinFormControl.setValue(spinSelected.name);

          this.spinFormControl.markAsDirty();
          this.resumeForm.markAsDirty();
        }
      });
    }
  } 

  public addOtherSpin(attribute: RAttribute) {
    attribute.value = null;
    attribute.userId = this.userId;
    this.commonService.addRAttribute(attribute).subscribe((ra) => {
      attribute.id = ra.id;
      this.resume.spinsId = ra.id;
      this.spins.push(attribute);
    });
  }

  public spinIsEmpty(): boolean {
    if (this.resume.spins == null) {
      return true;
    } else {
      return false;
    }
  }

  public profileSummaryIsEmpty(): boolean {
    if (this.resume.profileSummaries.length == 0) {
      return true;
    } else {
      return false;
    }
  }

  public getSpinNameErrorMessage() {
    return "Spin is required";
  }

  public onIsClientHiddenChange({ checked }: { checked: boolean }) {
    this.resume.isClientHidden = checked;
  }

  public onIsProfessionalResumeChange({ checked }: { checked: boolean }) {
    this.resume.isProfessionalResume = checked;
  }

  public onIsProjectMostRecentChange({ checked }: { checked: boolean }) {
    this.resume.isProjectMostRecent = checked;
    if (this.resume.isProjectMostRecent)
      this.resume.projects = [...this.resume.projects].sort((a, b) => {
        return this.sortByProjectDate(a, b, true);
      });
    else
      this.resume.projects = [...this.resume.projects].sort((a, b) => {
        return this.sortByProjectDate(b, a, false);
      });
    this.refreshDropDownList = true;
  }

  private createObject(id: number, type: string): any {
    // if the toggle is on, use the default user's id
    const userId = this.isSlideChecked
      ? this.altOnlinerDefault.altOnlinerUserId
      : this.userId;
    switch (type) {
      case "skill":
        return new RSkill(
          id,
          null,
          -1,
          "",
          userId,
          new Date(),
          false,
          false,
          -1,
          null,
          -1,
          -1,
          null,
          -1,
          null,
          -1
        );
      case "skillSummary":
        return new SkillSummary(id, "", "", userId, new Date(), false);
      case "education":
        return { ...new EducationModel(), id };
      case "association":
        return new RAssociation(id, null, -1, new Date(), "", userId, false);
      case "project":
        return {
          ...new RProject(),
          id,
        };
      default:
        return null;
    }
  }

  public isReadOnly(): boolean {
    return (
      this.resume &&
      this.resume.statusType &&
      (this.resume.statusType.name === ResumeStatusTypes.APPROVED ||
        this.resume.statusType.name === ResumeStatusTypes.PENDING_CM_REVIEW ||
        this.resume.statusType.name === ResumeStatusTypes.PENDING_RMT_REVIEW)
    );
  }

  public isSkillSummaryFormArrayValid(): boolean {
    return (
      !this.skillSummaryFormArray.invalid &&
      this.skillSummaryFormArray.value.length > 0 &&
      this.skillSummaryFormArray.value.every((ss: number) => ss > 0)
    );
  }

  public isSkillFormArrayValid(): boolean {
    return (
      !this.skillFormArray.invalid &&
      this.resume.skills.length > 0 &&
      this.resume.skills.every((ss: RSkill) => ss.skillId > 0)
    );
  }

  public isUserSkillFormArrayValid(): boolean {
    return (
      !this.userSkillFormArray.invalid &&
      this.resume?.resumeExpertiseGroups?.length > 0 &&
      this.resume?.resumeExpertiseGroups?.every((ss: RResumeExpertiseGroup) => ss.skillId > 0 && ss.expertiseTypeId > 0)
    );
  }

  public isEducationFormArrayValid(): boolean {
    return (
      !this.educationFormArray.invalid && this.resume.educations.length > 0
    );
  }

  public isProjectFormArrayValid(): boolean {
    return (
      !this.projectFormArray.invalid &&
      this.resume.projects.length > 0 &&
      this.resume.projects.every((ss: RProject) => ss.id > 0)
    );
  }

  public isAssociationFormArrayValid(): boolean {
    return (
      (!this.associationFormArray.invalid &&
        this.associationFormArray.length === 0) ||
      this.associationFormArray.value.every((ss: number) => ss > 0)
    );
  }

  public isAccreditationFormControlValid(): boolean {
    const accreditationStr = this.accreditationFormControl.value;
    return !accreditationStr || accreditationStr.length <= 40;
  }

  public isProfileFormControlValid(): boolean {
    return this.profileSummaryFormControl.value !== -1;
  }

  public isSpinFormControlValid(): boolean {
    var spinValid = true;

    if (this.spinFormControl.value == -1)
      spinValid = false;

    return spinValid;
  }

  public shouldEnablePreview(): boolean {
    return (
      this.isAccreditationFormControlValid() &&
      this.isProfileFormControlValid() &&
      this.isSkillSummaryFormArrayValid() &&
      this.isUserSkillFormArrayValid() &&
      this.isEducationFormArrayValid() &&
      this.isProjectFormArrayValid() &&
      this.isAssociationFormArrayValid() &&
      this.resume.id > 0
    );
  }

  public isGoldResume(): boolean {
    return (
      this.resume?.statusType?.name === ResumeStatusTypes.APPROVED &&
      this.resume?.dateApproved === this.resume?.maxApprovedSpinDate
    );
  }

  public submitConfirm() {
    const result = this.showConfirmationDialog(
      "Create Request for review",
      "Do you want to create a request to have this build reviewed?",
      "Yes",
      "No"
    );

    result.afterClosed().subscribe(async (r) => {
      if (r === "ok") {
        this.disableForm();
        this.isRequesting$.next((this.isRequesting = true));
        this.selected = 1;

        // if the toggle is on, use the default user's id
        const userId = this.isSlideChecked
          ? this.altOnlinerDefault.altOnlinerUserId
          : this.userId;

        this.workflowService.requestReview(userId, this.resume.id).subscribe(
          (_response: any) => {
            this.postSaveSuccess(this.resume.id);
            this.refreshList.emit();
          },
          (error) => this.postSaveError(error)
        );
      }
    });
  }

  public submitAndSaveConfirm() {
    const result = this.showConfirmationDialog(
      "Create Request for review",
      "Do you want to save and create a request to have this build reviewed?",
      "Yes",
      "No"
    );

    result.afterClosed().subscribe(async (r) => {
      if (r === "ok") {
        this.disableForm();
        this.isRequesting$.next((this.isRequesting = true));
        this.selected = 1;
        this.save(1);
      }
    });
  }

  public save(buttonClick: number): void {
    if (this.isSubmitting) return;
    // this.sanitizeResume();
    this.disableForm();
    this.isSubmitting$.next((this.isSubmitting = true));
    let postResume = { ...this.resume };
    postResume.accreditation = postResume.accreditation;
    // if the toggle is on, use the default user's id
    const userId = this.isSlideChecked
      ? this.altOnlinerDefault.altOnlinerUserId
      : this.userId;
    postResume.spins = this.spins.filter(
      (x) => x.id === postResume.spinsId
    )[0];

    //other
    if (postResume.spins.id === 0) {
      postResume.spins.name = this.spin.value.name;
      postResume.spins.isOther = true;
    }
    postResume.spins.name = postResume.spins.name.replace(
      /(\\|\/|\:|\*|\?|\"|\<|\>|\|)+/,
      ""
    );
    if (postResume.spins.name === "") {
      this.postSaveError("string error");
    }

    this.selected = buttonClick;

    if (postResume.id < 0) {
      if (this.cloneInProgress && this.cloneResumeStatus == "Approved") {
        this.resumeService
          .cloneResume(postResume, userId, this.refResumeId.toString())
          .subscribe(this.saveObserver);
      } else {
        this.resumeService
          .postNewResume(postResume, userId)
          .subscribe(this.saveObserver);
      }
    } else {
      this.resumeService
        .updateResume(postResume, userId)
        .subscribe(this.saveObserver);
    }
    // update the revertable model once saved
    this.createRevertableModel();
  }

  private saveObserver = {
    next: ({ id: resumeId }) => {
      if (this.selected === 1) {
        this.workflowService
          .requestReview(this.userId, resumeId)
          .subscribe((_response: any) => {
            this.postSaveSuccess(resumeId);
          });
      } else {
        this.postSaveSuccess(resumeId);
      }
    },
    error: (error) => this.postSaveError(error),
  };

  private enableForm(): void {
    this.resumeForm.enable({ emitEvent: false });
  }

  private disableForm(): void {
    this.resumeForm.disable({ emitEvent: false });
  }

  private postSaveSuccess(resumeId: number) {
    this.getResume(resumeId).subscribe((resume: Resume) => {
      this.resume = resume;
      this.enableForm();
      if (this.selected === 1) {
        this.snackBarService.message(
          "Request to Review Resume submitted successfully!"
        );
        this.refreshList.emit();
      } else {
        this.snackBarService.message("Resume saved successfully!");
      }

      this.isRequesting = false;
      this.isRequesting$ = new BehaviorSubject<boolean>(this.isRequesting);

      this.isSubmitting = false;
      this.isSubmitting$ = new BehaviorSubject<boolean>(this.isSubmitting);
      this.resumeForm.markAsPristine();
      this.buildResumeStateService.setFormsState(
        BuildResumeState.pristine,
        resume
      );
      this.isAfterSaving = true;
    });
  }

  private postSaveError(error: any) {
    this.enableForm();
    this.snackBarService.message(error);
    this.isRequesting = false;
    this.isRequesting$ = new BehaviorSubject<boolean>(this.isRequesting);

    this.isSubmitting = false;
    this.isSubmitting$ = new BehaviorSubject<boolean>(this.isSubmitting);
    this.isAfterSaving = true;
    this.refreshList.emit();
  }

  public cancel(): void {
    this.effectiveCancel();
  }

  public close(): void {
    // collapse card and reload list
    this.refreshList.emit();
  }

  private async effectiveCancel(): Promise<void> {
    if (this.resume.id < 0) {
      this.markNewResumeCloning.emit(false);
      this.buildResumeStateService.setFormsState(
        BuildResumeState.pristine,
        null
      );
      this.delete.emit();
    } else if (!this.resumeForm.dirty) {
      this.panelOpenState = !this.panelOpenState;

      this.cdr.detectChanges();
    } else {
      this.panelOpenState = !this.panelOpenState;

      await this.loadResume(this.resume.id);
      this.resume = { ...this.model };
      this.initForm();

      this.initFormArrays();
      this.resumeForm.markAsUntouched();
      this.buildResumeStateService.setFormsState(
        BuildResumeState.pristine,
        null
      );

      this.refreshList.emit();
      this.cdr.detectChanges();
    }
    this.resumeForm.markAsPristine();
  }

  onInfoClicked() {
    const confirmDialog = new ConfirmDialog();
    confirmDialog.title = "Skills Grouping";
    confirmDialog.message =
      "Up to four groupings can be used in the Resume.</p>";
    confirmDialog.cancelButtonTitle = null;
    confirmDialog.okButtonTitle = "Ok";

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data: confirmDialog,
      disableClose: true,
    });
  }

  public togglePreview() {
    this.showPreview = !this.showPreview;
  }

  public togglePanel($event): void {
    if ($event.defaultPrevented) {
      return;
    }

    if (this.showPreview) {
      this.showPreview = false;
    }

    this.panelOpenState =
      this.formsListState !== BuildResumeState.isDirty &&
      !this.resumeForm.dirty;
    if (!this.panelOpenState) {
      const dialogRef = this.showGenericConfirmationDialog();
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result === "ok") {
          await this.effectiveTogglePanel();

          this.resume = { ...this.model };

          this.panelOpenState = true;

          this.buildResumeStateService.setFormsState(
            BuildResumeState.pristine,
            null
          );
          this.resumeForm.markAsPristine();

          this.cdr.detectChanges();
        }
      });
    } else {
      this.effectiveTogglePanel();
      this.open.emit();
      //this.initArrayList();
    }

    this.cdr.detectChanges();
  }

  private async effectiveTogglePanel(): Promise<boolean> {
    if (!this.fullyLoaded) {
      return this.loadResume(this.resume.id);
    } else {
      new Promise((resolve) => {
        this.createRevertableModel();
        resolve(true);
      });
    }
  }

  private async loadResume(resumeId: number): Promise<boolean> {
    this.isLoading$.next((this.isLoading = true));
    return new Promise(async (resolve) => {
      this.getResume(resumeId).subscribe(
        (data: Resume) => {
          this.resume = {
            ...this.resume,
            spins: data.spins,
            associations: data.associations,
            skillSummaries: data.skillSummaries,
            projects: data.projects,
            educations: data.educations,
            skills: data.skills,
            userSkills: data.skills,
            resumeExpertiseGroups: data.resumeExpertiseGroups,
            isSnapshot: data.isSnapshot,
          };

          this.createRevertableModel();

          this.initArrayList();

          this.initFormArrays();

          this.spinFormControl.setValue(this.resume.spins.name);

          this.markFullyLoaded.emit(this.resume.id);

          this.isLoading$.next((this.isLoading = false));

          resolve(true);
        },
        (error) => {
          this.snackBarService.message(error);
          this.cancel();
        }
      );
    });
  }

  private getResume(resumeId: number): Observable<Resume> {
    // if the toggle is on, use the default user's id
    const userId = this.isSlideChecked
      ? this.altOnlinerDefault.altOnlinerUserId
      : this.userId;
    return this.resumeService.getSnapshotResume(userId, resumeId);
  }

  private getSpinFromList(): RAttribute {
    if (this.spin.value)
        return this.spins.find(result => result.id == this.spin.value.id);
    }

  private createRevertableModel() {
    // store a revertable state
    this.model = JSON.parse(JSON.stringify(this.resume)); //deep clone
  }

  private initFormArrays(): void {
    this.initSkillSummariesFormArray(this.resume.skillSummaries);
    this.initSkillsFormArray(this.resume.skills);
    this.initUserSkillsFormArray(this.resume.userSkills);
    this.initResumeExpertiseGroupsFormArray(this.resume.resumeExpertiseGroups);
    this.initEducationsFormArray(this.resume.educations);
    this.initProjectsFormArray(this.resume.projects);
    this.initAssociationsFormArray(this.resume.associations);

    if (this.isReadOnly()) {
      this.spin.disable();
      this.disableForm();
    }
  }

  private initSkillSummariesFormArray(skillSummaries: SkillSummary[]) {
    this.skillSummaryFormArray.clear();
    skillSummaries.forEach((obj) => {
      const control = new UntypedFormControl(obj.id);

      this.attachDuplicateSelectionValidationDataChangeObserver(
        control,
        this.skillSummaryFormArray
      );

      this.skillSummaryFormArray.push(control);
    });
  }

  private initSkillsFormArray(skills: RSkill[]) {
    this.skillFormArray.clear();

    skills.forEach((obj) => {
      const control = new UntypedFormControl(obj.id);
      this.attachDuplicateSelectionValidationDataChangeObserver(
        control,
        this.skillFormArray
      );

      this.attachSkillMaxGroupSelectionValidationDataChangeObserver(control);
      this.skillFormArray.push(control);
    });
  }

  private initUserSkillsFormArray(userSkills: RSkill[]) {
    this.userSkillFormArray.clear();

    userSkills.forEach((obj) => {
      const control = new UntypedFormControl(obj.id);
      this.attachDuplicateSelectionValidationDataChangeObserver(
        control,
        this.userSkillFormArray
      );

      this.attachSkillMaxGroupSelectionValidationDataChangeObserver(control);
      this.userSkillFormArray.push(control);
    });
  }

  private initResumeExpertiseGroupsFormArray(resumeExpertiseGroups: RResumeExpertiseGroup[]) {
    this.resumeExpertiseGroupFormArray.clear();

    resumeExpertiseGroups.forEach((obj) => {
      const control = new UntypedFormControl(obj.skillId);
      this.attachDuplicateSelectionValidationDataChangeObserver(
        control,
        this.resumeExpertiseGroupFormArray
      );

      this.attachSkillMaxGroupSelectionValidationDataChangeObserver(control);
      this.resumeExpertiseGroupFormArray.push(control);
    });

    this.resumeExpertiseGroups = resumeExpertiseGroups;
  }

  private initEducationsFormArray(educations: EducationModel[]) {
    this.educationFormArray.clear();
    educations.forEach((obj) => {
      const control = new UntypedFormControl(obj.educationId);
      this.attachDuplicateSelectionValidationDataChangeObserver(
        control,
        this.educationFormArray
      );

      this.educationFormArray.push(control);
    });
  }

  private initProjectsFormArray(projects: RProject[]) {
    this.projectFormArray.clear();
    projects.forEach((obj) => {
      const control = new UntypedFormControl(obj.id);
      this.attachDuplicateSelectionValidationDataChangeObserver(
        control,
        this.projectFormArray
      );

      this.projectFormArray.push(control);
    });
  }

  private initAssociationsFormArray(associations: RAssociation[]) {
    this.associationFormArray.clear();
    associations.forEach((obj) => {
      const control = new UntypedFormControl(obj.assocId);
      this.attachDuplicateSelectionValidationDataChangeObserver(
        control,
        this.associationFormArray
      );

      this.associationFormArray.push(control);
    });
  }

  private generateNewControlId(currentIds: number[]): number {
    const newId = currentIds.filter((x) => x < 0);
    return newId.length === 0 ? -1 : Math.min(...currentIds) - 1;
  }

  public removeFromSkillSummaryList(index: number): void {
    this.resume.skillSummaries = this.removeFromMultiDropdownList(
      this.skillSummaryFormArray,
      this.skillSummaries,
      index
    );
  }

  public removeFromSkillList(index: number): void {
    this.resume.skills = this.removeFromMultiDropdownList(
      this.skillFormArray,
      this.skills,
      index
    );
  }

  public removeFromUserSkillList(index: number): void {
    this.resume.userSkills = this.removeFromMultiDropdownList(
      this.userSkillFormArray,
      this.userSkills,
      index
    );
  }

  private removeFromMultiDropdownList<T>(
    formArray: UntypedFormArray,
    dropdownElements: DropDown<T>[],
    index: number
  ): T[] {
    formArray.removeAt(index);

    this.resumeForm.markAsDirty();

    return this.mapFormArrayToElements(formArray, dropdownElements);
  }

  public removeFromEducationList(index: number): void {
    this.resume.educations = this.removeFromMultiDropdownList(
      this.educationFormArray,
      this.educations,
      index
    );
  }

  public removeFromProjectList(index: number): void {
    this.resume.projects = this.removeFromMultiDropdownList(
      this.projectFormArray,
      this.projects,
      index
    );
  }

  public removeFromAssociationList(index: number): void {
    this.resume.associations = this.removeFromMultiDropdownList(
      this.associationFormArray,
      this.associations,
      index
    );
  }

  public skillSummaryChange({
    index,
    id,
  }: {
    index: number;
    id: number;
  }): void {
    this.resume.skillSummaries[index] = this.getDropdownElement(
      this.skillSummaries,
      id
    );
  }

  public skillChange({ index, id }: { index: number; id: number }): void {
    this.resume.skills[index] = this.getDropdownElement(this.skills, id);
  }

  public userSkillChange({ index, id }: { index: number; id: number }): void {
    this.resume.userSkills[index] = this.getDropdownElement(this.userSkills, id);
  }

  public educationChange({ index, id }: { index: number; id: number }): void {
    this.resume.educations[index] = this.getDropdownElement(
      this.educations,
      id
    );
  }

  public rightSideEducationChange(
    rightSideEducation: MultiSelectOption<EducationModel>[]
  ): void {
    this.resume.educations = rightSideEducation.map((x) => x.element);
    this.buildResumeStateService.setFormsState(
      BuildResumeState.isDirty,
      null
    );
  }

  public rightSideAssociationsChange(
    rightSideAssociations: MultiSelectOption<RAssociation>[]
  ): void {
    this.resume.associations = rightSideAssociations.map((x) => x.element);
    this.buildResumeStateService.setFormsState(
      BuildResumeState.isDirty,
      null
    );
  }

  public rightSideSkillsChange(
    selectedOptions: MultiSelectOption<DropDown<any>>[]
  ): void {
    this.resume.skills = selectedOptions.map((x) => x.element);
    this.buildResumeStateService.setFormsState(
      BuildResumeState.isDirty,
      null
    );
  }

  public rightSideUserSkillsGroupingsChange(
    selectedOptions: RResumeExpertiseGroup[]
  ): void {
    this.resume.resumeExpertiseGroups = selectedOptions;

    if(this.resumeForm.dirty) {
      this.buildResumeStateService.setFormsState(
        BuildResumeState.isDirty,
        null
      );
    } else {
      this.buildResumeStateService.setFormsState(
        BuildResumeState.pristine,
        null
      );
    }
  }

  public rightSideProjectsChange(
    selectedOptions: MultiSelectOption<DropDown<any>>[]
  ): void {
    this.resume.projects = selectedOptions.map((x) => x.element);
    this.buildResumeStateService.setFormsState(
      BuildResumeState.isDirty,
      null
    );
  }

  public validateSkillExpertise(
    selected: DropDown<RSkill>,
    current: Map<number, DropDown<RSkill>>
  ): string {
    const maxExpertiseType = 4;
    let distinctExpertise = [];

    // Populates array with distinct expertiseTypeId values
    current.forEach((skillMap) => {
      if (!distinctExpertise.includes(skillMap.element.expertiseTypeId)) {
        distinctExpertise.push(skillMap.element.expertiseTypeId);
      }
    });

    if (
      distinctExpertise.length == maxExpertiseType &&
      !distinctExpertise.includes(selected.element.expertiseTypeId)
    ) {
      return `You can only have a max of ${maxExpertiseType} expertise.`;
    }
    return null;
  }

  getSortedSkillsDropDown = (
    dropDownMap: Map<number, DropDown<RSkill>>
  ): Map<number, DropDown<any>> => {
    return new Map(
      [...dropDownMap]
        .filter((a) => a[1].element.isStarred)
        .sort((a, b) =>
          String(a[1].viewValue.toUpperCase()).localeCompare(
            b[1].viewValue.toUpperCase()
          )
        )
        .concat(
          [...dropDownMap]
            .filter((a) => !a[1].element.isStarred)
            .sort((a, b) =>
              String(a[1].viewValue.toUpperCase()).localeCompare(
                b[1].viewValue.toUpperCase()
              )
            )
        )
    );
  };

  getSortedSkillsRightDropDown = (
    dropDownMap: Map<number, DropDown<RSkill>>
  ): Map<number, DropDown<any>> => {
    return new Map(
      [...dropDownMap]
        .sort((a, b) =>
          String(a[1].viewValue.toUpperCase()).localeCompare(
            b[1].viewValue.toUpperCase()
          )
        )
    );
  };

  getSortedProjectsDropDown = (
    dropDownMap: Map<number, DropDown<RProject>>
  ): Map<number, DropDown<any>> => {
    this.refreshDropDownList = false;
    if (this.resume.isProjectMostRecent)
      return new Map(
        [...dropDownMap].sort((a, b) => {
          return this.sortByProjectDate(a[1].element, b[1].element, true);
        })
      );
    return new Map(
      [...dropDownMap].sort((a, b) => {
        return this.sortByProjectDate(b[1].element, a[1].element, false);
      })
    );
  };

  private sortByProjectDate(curr: RProject, next: RProject, mostRecent: boolean): number {
    let nextStart = new Date(next.startDate).getTime();
    let currStart = new Date(curr.startDate).getTime();

    //Sorting the most recent for null end dates
    if (mostRecent && !curr.endDate) {
      return (!next.endDate) ? nextStart - currStart : -1;
    }
    //Sorting the oldest for null end dates
    else if (!mostRecent && !next.endDate) {
      return (!curr.endDate) ? nextStart - currStart : 1;
    }
    //for same start date
    else if (nextStart === currStart) {
      //handle null or present end date
      if (!next.endDate && !curr.endDate) return 0;
      if (!next.endDate) return 1;
      if (!curr.endDate) return -1;

      return (
        new Date(next.endDate).getTime() - new Date(curr.endDate).getTime()
      );
    }

    return nextStart - currStart;
  }

  public projectChange({ index, id }: { index: number; id: number }): void {
    this.resume.projects[index] = this.getDropdownElement(this.projects, id);
  }

  public associationChange({ index, id }: { index: number; id: number }): void {
    this.resume.associations[index] = this.getDropdownElement(
      this.associations,
      id
    );
  }

  private addToMultiDropdownList<T>(
    formArray: UntypedFormArray,
    instanceOf: string,
    validators: string[]
  ): T {
    const id = this.generateNewControlId(formArray.value);
    const control = new UntypedFormControl(id);

    if (validators.includes("DuplicateSelectionValidation")) {
      this.attachDuplicateSelectionValidationDataChangeObserver(
        control,
        formArray
      );
    }

    if (validators.includes("SkillMaxGroupSelectionValidation")) {
      this.attachSkillMaxGroupSelectionValidationDataChangeObserver(control);
    }

    formArray.insert(0, control);
    this.resumeForm.markAsDirty();

    return this.createObject(id, instanceOf);
  }

  public addToSkillSummaryList(): void {
    const skillSummary = this.addToMultiDropdownList<SkillSummary>(
      this.skillSummaryFormArray,
      "skillSummary",
      ["DuplicateSelectionValidation"]
    );
    this.resume.skillSummaries.unshift(skillSummary);
  }

  public addToSkillList(): void {
    const skill = this.addToMultiDropdownList<RSkill>(
      this.skillFormArray,
      "skill",
      ["DuplicateSelectionValidation", "SkillMaxGroupSelectionValidation"]
    );
    this.resume.skills.unshift(skill);
  }

  public addToUserSkillList(): void {
    const skill = this.addToMultiDropdownList<RSkill>(
      this.userSkillFormArray,
      "skill",
      ["DuplicateSelectionValidation", "SkillMaxGroupSelectionValidation"]
    );
    this.resume.userSkills.unshift(skill);
  }

  public addToEducationList(): void {
    const education = this.addToMultiDropdownList<EducationModel>(
      this.educationFormArray,
      "education",
      ["DuplicateSelectionValidation"]
    );
    this.resume.educations.unshift(education);
  }

  public addToProjectList(): void {
    const project = this.addToMultiDropdownList<RProject>(
      this.projectFormArray,
      "project",
      ["DuplicateSelectionValidation"]
    );
    this.resume.projects.unshift(project);
  }

  public addToAssociationList(): void {
    const association = this.addToMultiDropdownList<RAssociation>(
      this.associationFormArray,
      "association",
      ["DuplicateSelectionValidation"]
    );
    this.resume.associations.unshift(association);
  }

  private getOrderedMultiDropdownList<T>(
    formArray: UntypedFormArray,
    dropdownElements: DropDown<T>[],
    propertyId: string
  ): T[] {
    const currentList = this.mapFormArrayToElements(
      formArray,
      dropdownElements
    );

    const item_order = [...formArray.value];

    this.resumeForm.markAsDirty();

    return this.mapOrder(currentList, item_order, propertyId);
  }

  public syncSkillSummaryList(): void {
    this.resume.skillSummaries = this.getOrderedMultiDropdownList(
      this.skillSummaryFormArray,
      this.skillSummaries,
      "id"
    );
  }

  public syncSkillList(): void {
    this.resume.skills = this.getOrderedMultiDropdownList(
      this.skillFormArray,
      this.skills,
      "skillId"
    );
  }

  public syncUserSkillList(): void {
    this.resume.userSkills = this.getOrderedMultiDropdownList(
      this.userSkillFormArray,
      this.userSkills,
      "skillId"
    );
  }

  public syncEducationList(): void {
    this.resume.educations = this.getOrderedMultiDropdownList(
      this.educationFormArray,
      this.educations,
      "educationId"
    );
  }

  public syncProjectList(): void {
    this.resume.projects = this.getOrderedMultiDropdownList(
      this.projectFormArray,
      this.projects,
      "id"
    );
  }

  public syncAssociationList(): void {
    this.resume.associations = this.getOrderedMultiDropdownList(
      this.associationFormArray,
      this.associations,
      "assocId"
    );
  }

  private mapOrder(array: any, order: number[], key: string): any[] {
    array.sort(function (a, b) {
      var A = a[key],
        B = b[key];

      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
    });

    return array;
  }

  private mapFormArrayToElements<T>(
    formArray: UntypedFormArray,
    dropdownElements: DropDown<T>[]
  ): T[] {
    return dropdownElements
      .filter((x) => formArray.value.includes(x.value))
      .map((x) => x.element);
  }

  private getDropdownElement<T>(dropdownElements: DropDown<T>[], id: number) {
    return dropdownElements
      .filter((x) => x.value === id)
      .map((x) => x.element)[0];
  }

  public openReviewCommentsDialog($event): void {
    $event.preventDefault();

    this.dialog.open(ReviewCommentsModalComponent, {
      width: "500px",
      maxHeight: "500px",
      data: { reviewComments: this.resume.reviewComments },
      panelClass: ["no-padding-dialog", "resume-review-comments"],
    });
  }

  public openPreviewDialog($event): void {
    $event.preventDefault();
    // if the toggle is on, use the default user's id
    const userId = this.isSlideChecked
      ? this.altOnlinerDefault.altOnlinerUserId
      : this.userId;
    this.dialog.open(ResumeOutputModal, {
      width: "800px",
      height: "90%",
      data: {
        resume: this.resume,
        userId: userId,
        resumeFullyLoaded: this.fullyLoaded,
        isNonOnliner: false,
      },
    });
  }

  public cloneResume($event): void {
    $event.preventDefault();

    if (this.cloneInProgress) {
      this.showConfirmationDialog(
        "Clone in progress!",
        "There is already one clone in progress. Please complete or cancel it.",
        "Ok"
      );
    } else {
      if (
        this.formsListState === BuildResumeState.isDirty ||
        this.resumeForm.dirty
      ) {
        const dialogRef = this.showGenericConfirmationDialog();
        dialogRef.afterClosed().subscribe((result) => {
          if (result === "ok") {
            this.markCloneResume.emit(this.resume);
          }
        });
      } else {
        this.markCloneResume.emit(this.resume);
      }

      this.cdr.detectChanges();
    }
    this.open.emit();
  }

  public async deleteResume($event): Promise<void> {
    $event.preventDefault();

    const dialogRef = this.showConfirmationDialog(
      "Delete Resume",
      "Do you want to delete this resume?",
      "Yes",
      "No"
    );

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === "ok") {
        this.isDeleting = true;
        this.cdr.detectChanges();
        let isDeleted = await this.resumeService
          .deleteResume(this.userId, this.resume.id)
          .toPromise();

        this.refreshList.emit();
        if (isDeleted) {
          this.snackBarService.message("Resume deleted successfully!");
        } else {
          this.snackBarService.message("Deleting failed");
        }
        this.isDeleting = false;
      }
    });
  }

  private showConfirmationDialog(
    title: string,
    message: string,
    okButtonTitle: string,
    cancelButtonTitle: string = undefined,
    flipButtonColor: boolean = false
  ): any {
    const confirmDialog = new ConfirmDialog();

    confirmDialog.title = title;
    confirmDialog.message = message;
    confirmDialog.okButtonTitle = okButtonTitle;
    confirmDialog.flipButtonColor = flipButtonColor;
    if (cancelButtonTitle) {
      confirmDialog.cancelButtonTitle = cancelButtonTitle;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data: confirmDialog,
      disableClose: true,
    });

    return dialogRef;
  }

  private showGenericConfirmationDialog(): any {
    const confirmDialog = new ConfirmDialog();

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data: confirmDialog,
      disableClose: true,
    });

    return dialogRef;
  }

  //Validators

  private observeSkillExpertiseFormArrayDataChangeForErrorCleaning() {
    this.skillFormArray.valueChanges.subscribe((value) => {
      Object.keys(this.skillFormArray.controls)
        .filter((key) => this.skillFormArray.controls[key].invalid)
        .forEach((key) => {
          const group = this.groupSkillExpertiseTypes();

          const selectedExpertiseType = this.getDropdownElement(
            this.skills,
            this.skillFormArray.controls[key].value
          )?.expertiseType?.id;

          const otherGroups = Object.keys(group).filter(
            (x) => x !== selectedExpertiseType.toString()
          );

          if (otherGroups.length < this.skillsMaxExpertiseType) {
            this.setFormError(
              this.skillFormArray.controls[key],
              "maxGroupSelectionError",
              null
            );
          }

          this.clearErrorsIfAllNull(this.skillFormArray.controls[key]);
        });
    });
  }

  private observeDefaultFormArrayDataChangeForErrorCleaning(
    formArray: UntypedFormArray
  ) {
    formArray.valueChanges.subscribe((value) => {
      Object.keys(formArray.controls)
        .filter((key) => formArray.controls[key].invalid)
        .forEach((key) => {
          const quantityOfSameItem = formArray.value.filter(
            (x: UntypedFormControl) => x === formArray.controls[key].value
          ).length;

          if (quantityOfSameItem === 1) {
            this.setFormError(
              formArray.controls[key],
              "duplicateSelectionError",
              null
            );
          }

          this.clearErrorsIfAllNull(formArray.controls[key]);
        });
    });
  }

  private attachDuplicateSelectionValidationDataChangeObserver(
    control: UntypedFormControl,
    formArray: UntypedFormArray
  ) {
    control.valueChanges
      .pipe(startWith(control.value), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        if (formArray.value.includes(next)) {
          this.setFormError(control, "duplicateSelectionError", {
            value: true,
          });
        }
      });
  }

  private attachSkillMaxGroupSelectionValidationDataChangeObserver(
    control: UntypedFormControl
  ) {
    control.valueChanges
      .pipe(startWith(control.value), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        const group = this.groupSkillExpertiseTypes();

        const quantityOfExpertiseTypes = Object.keys(group).length;

        const selectedItem = this.getDropdownElement(this.skills, next);

        const allElementsInFormArray = this.mapFormArrayToElements(
          this.skillFormArray,
          this.skills
        );

        const selectedExpertiseType = selectedItem?.expertiseType?.id;

        //check if the existing expertise type has already another
        //item with the same expertise type that contains an error

        if (!Object.keys(group).includes(selectedExpertiseType.toString())) {
          if (quantityOfExpertiseTypes >= this.skillsMaxExpertiseType) {
            this.setFormError(control, "maxGroupSelectionError", {
              value: true,
            });
          }
        }
      });
  }

  private setFormError(control: UntypedFormControl, error: string, errorValue: any) {
    control.setErrors({
      ...control.errors,
      [error]: errorValue,
    });
  }

  private groupSkillExpertiseTypes() {
    //filter by the items that are in the form array
    //and apply the reducer
    let group = this.skills
      .filter((x) => this.skillFormArray.value.includes(x.value))
      .reduce((r, a) => {
        r[a.element.expertiseType?.id] = [
          ...(r[a.element.expertiseType?.id] || []),
          a,
        ];
        return r;
      }, {});

    return group;
  }

  private clearErrorsIfAllNull(control: UntypedFormControl): void {
    if (!this.doesFormControlContainError(control)) {
      control.setErrors(null);
    }
  }

  private doesFormControlContainError(control: UntypedFormControl): boolean {
    return Object.values(control.errors).some((x) => x !== null);
  }

  public getResumeStatusColor() {
    let color = "";
    if (
      this.resume.statusType?.name === ResumeStatusTypes.PENDING_CM_REVIEW ||
      this.resume.statusType?.name === ResumeStatusTypes.PENDING_RMT_REVIEW
    ) {
      color = "amber-status";
    } else if (
      this.resume.statusType?.name === ResumeStatusTypes.REVISION_REQUIRED_CM ||
      this.resume.statusType?.name === ResumeStatusTypes.REVISION_REQUIRED_RMT
    ) {
      color = "red-status";
    } else {
      color = "";
    }
    return color;
  }

  // prevents objects with errors to be saved
  private sanitizeResume() {
    // check arrays one by one here
    let result = [];

    for (let index = 0; index < this.resume.skills.length; index++) {
      const element = this.resume.skills[index];
      const item = this.skillFormArray.controls.find((ctrl) => {
        return element.id === ctrl.value && ctrl.status === "INVALID";
      });

      if (item) {
        result.push(index);
      }
    }

    for (let index = result.length - 1; index >= 0; index--) {
      this.resume.skills.splice(result[index], 1);
    }
  }
}

import { environment } from "../../../environments/environment";
import { Observable, of, throwError, Subject } from "rxjs";
import { AuthorizedHttp } from "./authorized-http.service";
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { EducationModel } from "../data-models/EducationModel";
import { CertificationStatus } from "../data-models/CertificationStatus";
import { EducationName } from "../data-models/commonDataModels";
import { CertificateAttachment } from "../data-models/CertificateAttachment";
import { FileData } from "../data-models/FileData";
import { DownloadFile } from "../data-models/DownloadFile";
import { Education } from "../data-models/Education";
import saveAs from "file-saver";
import { CommonService } from "./common.service";

@Injectable()
export class EducationService {
  private _apiEndpoint = `${environment.apiEndpoint}/api/education`;
  private _educationRecords: EducationModel[];
  private _certificationStatus: CertificationStatus[];
  private _educationNames: EducationName[];
  private _educationRecord: EducationModel;
  private _attachment: CertificateAttachment;
  private _hasAttachment: boolean;
  private _isDeleted: boolean;

  constructor(private http: AuthorizedHttp, private commonService: CommonService) {
    let headers: any = {
      "Content-Type":
        "application/json; charset=utf-8" /*or whatever type is relevant */,
      Accept: "application/json",
    };
  }

  public getAllEducationRecords(approvalType: string): Observable<EducationModel[]> {
    return this.http.get(`${this._apiEndpoint}/allRecords/${approvalType}`).pipe(
      map((result) => {
        this._educationRecords = result;
        return this._educationRecords;
      }),
      catchError((errorResponse: any) =>
        throwError(errorResponse.error.exceptionMessage || "Server error")
      )
    );
  }

  public getAllEducationRecordsByUserId(approvalType: string, userId: string): Observable<EducationModel[]> {
    userId = this.commonService.encrypt(userId);
    return this.http.get(`${this._apiEndpoint}/allRecords/${approvalType}/${userId}`).pipe(
      map((result) => {
        this._educationRecords = result;
        return this._educationRecords;
      }),
      catchError((errorResponse: any) =>
        throwError(errorResponse.error.exceptionMessage || "Server error")
      )
    );
  }

  public getEducationNames(): Observable<EducationName[]> {
    return this.http.get(`${this._apiEndpoint}/getEducationNames`).pipe(
      map((result) => {
        this._educationNames = result;
        return this._educationNames;
      }),
      catchError((errorResponse: any) =>
        throwError(errorResponse.error.exceptionMessage || "Server error")
      )
    );
  }

  public getMyEducationRecords(userId: string): Observable<EducationModel[]> {
    userId = this.commonService.encrypt(userId);
    return this.http
      .get(`${this._apiEndpoint}/myEducationRecords/` + userId)
      .pipe(
        map((result) => {
          this._educationRecords = result;
          return this._educationRecords;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }

  public getMyCertificationsAndCourses(
    userId: string
  ): Observable<EducationModel[]> {
    userId = this.commonService.encrypt(userId);
    return this.http
      .get(`${this._apiEndpoint}/myCertificationsAndCourses/` + userId)
      .pipe(
        map((result) => {
          this._educationRecords = result;
          return this._educationRecords;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }
  public postNewEducationRecord(
    educationModel: EducationModel,
    userId: string,
    sendApprovalEmail: boolean
  ): Observable<EducationModel> {
    userId = this.commonService.encrypt(userId);
    return this.http
      .post(
        `${this._apiEndpoint}/addNewEducationRecord/` +
          userId +
          `/` +
          sendApprovalEmail,
        JSON.stringify(educationModel)
      )
      .pipe(
        map((result) => {
          this._educationRecord = result;
          return this._educationRecord;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }
  public updateEducationRecord(
    educationModel: EducationModel,
    userId: string,
    sendApprovalEmail: boolean
  ): Observable<EducationModel> {
    userId = this.commonService.encrypt(userId);
    return this.http
      .patch(
        `${this._apiEndpoint}/updateCertCourses/` +
          userId +
          `/` +
          sendApprovalEmail,
        JSON.stringify(educationModel)
      )
      .pipe(
        map((result) => {
          this._educationRecord = result;
          return this._educationRecord;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }
  public rejectCertApproval(
    educationModel: EducationModel
  ): Observable<EducationModel> {
    return this.http
      .patch(
        `${this._apiEndpoint}/rejectCertification/`,
        JSON.stringify(educationModel)
      )
      .pipe(
        map((result) => {
          this._educationRecord = result;
          return this._educationRecord;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }
  public getCertAttachment(Id: number): Observable<CertificateAttachment> {
    return this.http
      .get(`${this._apiEndpoint}/certificateAttachment/` + Id)
      .pipe(
        map((result) => {
          this._attachment = result;
          return this._attachment;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }
  public hasCertAttachment(Id: number): Observable<boolean> {
    return this.http
      .get(`${this._apiEndpoint}/hasCertificateAttachment/` + Id)
      .pipe(
        map((result) => {
          this._hasAttachment = result;
          return this._hasAttachment;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }
  public deleteCertificateAttachement(fileId: number): Observable<Boolean> {
    return this.http
      .delete(`${this._apiEndpoint}/deleteAttachement/` + fileId)
      .pipe(
        map((result) => {
          this._isDeleted = result;
          return this._isDeleted;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }
  public postAddNewCertFile(
    fileData: FileData
  ): Observable<EducationModel> {
    return this.http
      //.postFile(`${this._apiEndpoint}/addNewCertFile/` + Id, formData)
      .post(`${this._apiEndpoint}/addNewCertFile/`, JSON.stringify(fileData))
      .pipe(
        map((result) => {
          this._educationRecord = result;
          return this._educationRecord;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }
  public downloadFile(filename: string)
  {
    return this.http
    .get(`${this._apiEndpoint}/downloadFile?fileName=` + filename)
      .pipe(
        map((result) => {
          this.saveFileLocally(result, filename);
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }

  public getImgData(filename: string) {
    return this.http
      .get(`${this._apiEndpoint}/downloadFile?fileName=` + filename)
      .pipe(
        map((fileData: DownloadFile) => {
          return fileData;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }

  private saveFileLocally(data: DownloadFile, filename: string) {
    var fileExt = filename.split('.').pop();
    var content = `data:image/` + fileExt + `;base64,` + data.content;
    saveAs(content, filename);
  }

  public deleteREducation(educationModel: Education, userId: string): Observable<Education> {
    userId = this.commonService.encrypt(userId);
    return this.http
        .patch(`${this._apiEndpoint}/deleteEducation/` + userId, JSON.stringify(educationModel))
        .pipe(
            map(result => {
                this._educationRecord = result;
                return this._educationRecord;
            }),
            catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
        );
  }
}

<mat-card fxFlex="100%" width="100%">
    <mat-card-header>
      <mat-card-title class="card-title">{{ title }}</mat-card-title>
    </mat-card-header>
    <div
      fxLayout="row"
      fxLayout.lt-sm="row"
      fxLayoutGap="8px"
      fxLayoutGap.lt-sm="4px"
      fxLayoutAlign="space-evenly stretch"
    >
        <div class="col-lg-12">
            <div class="row">
                <mat-error class="mat-error" *ngIf="errorMessage">
                    {{ errorMessage }}
                </mat-error>
            </div>
            <div fxLayout="row" class="fx-layout-row">
                <form class="col-md-3 col-lg-3" *ngIf="!isReadOnly">
                    <mat-card class="mat-card-height">
                        <div
                            class="ml-2 left-card-heading d-flex flex-row"
                            fxLayout="row"
                            *ngIf="!isReadOnly"
                        >
                            <div fxLayout="row" class="mr-3 list-heading">
                            {{ leftTitle }}
                            </div>
                        </div>
                        <div #leftSideElement>
                            <ul cdkDropList
                                id="leftSideSkills"
                                [cdkDropListData]="leftSide"
                                [cdkDropListConnectedTo]="['groupinglist1', 'groupinglist2', 'groupinglist3', 'groupinglist4']"
                                class="obs-multi-select-box" size="17">
                                <li class="onliner-skills-box"
                                    title=""                                    
                                    *ngFor="let item of leftSide; index as i"
                                    cdkDrag>
                                    <div>{{ item.viewValue }}</div>
                                </li>
                            </ul>
                        </div>
                    </mat-card>
                </form>
                <div class="visible-md" *ngIf="!isReadOnly"></div>
                <form [ngClass]="isReadOnly ? 'col-md-12 col-lg-12' : 'col-md-9 col-lg-9'">
                    <mat-card class="row mat-card-height" [ngClass]="{ disabled: isReadOnly }">
                        <div id="expertise-dropdowns" class="expertise-groups-row">
                            <div class="halign">
                                <div id="group1divdd">
                                    <mat-form-field id="group1" fxFlex="fieldLength" class="col-md-12 no-padding-left" (click)="displaySkillGroupingPickList($event, 'group1')">
                                        <mat-label>Skill Grouping 1</mat-label>
                                          <input matInput 
                                          [formControl]="expertiseType1" 
                                          type="text"
                                          placeholder="Select Skill Grouping"
                                          aria-label="Skill Grouping 1"
                                          name="expertiseType1Input"
                                          value="getInputControlValue(expertiseType1)"
                                          (keydown)="displaySkillGroupingPickList($event, 'group1')"/>
                                        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                                      </mat-form-field>
                                </div>
                                <div id="group1divlist">
                                    <ul cdkDropList 
                                        id="groupinglist1"
                                        [cdkDropListData]="skillGroup1"
                                        [cdkDropListConnectedTo]="['leftSideSkills', 'groupinglist2', 'groupinglist3', 'groupinglist4']"
                                        (cdkDropListDropped)="isSkillGroup1Enabled && dropGroup1($event)"
                                        style="max-height: calc(100% - 20px);"
                                        [ngClass]="isSkillGroup1Enabled ? 'obs-multi-select-skill-box' : 'obs-multi-select-skill-box-disabled'">
                                        <li *ngFor="let item of skillGroup1; index as i"
                                            [ngClass]="isReadOnly === true ? 'li-disabled' : 'obs-option'"
                                            draggable="isSkillGroup1Enabled === true && isReadOnly === false"                                            
                                            title=""
                                            cdkDrag
                                            [cdkDragDisabled]="isReadOnly">
                                            <div>
                                                <div class="skill-custom-placeholder" *cdkDragPlaceholder></div>
                                                {{ item.viewValue }}
                                            </div>
                                            <i *ngIf="!isReadOnly"
                                                class="material-icons remove-list-row"
                                                (click)="rightOption1Click(i, item)">remove</i>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="halign">
                                <div id="group2divdd">
                                    <mat-form-field fxFlex="fieldLength" class="col-md-12 no-padding-left" (click)="displaySkillGroupingPickList($event, 'group2')">
                                        <mat-label>Skill Grouping 2</mat-label>
                                          <input matInput 
                                          [formControl]="expertiseType2" 
                                          type="text"
                                          placeholder="Select Skill Grouping"
                                          aria-label="Skill Grouping 2"
                                          name="expertiseType2Input"
                                          value="getInputControlValue(expertiseType2)"
                                          (keydown)="displaySkillGroupingPickList($event, 'group2')"/>
                                        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                                      </mat-form-field>
                                </div>
                                <div id="group2divlist">
                                    <ul cdkDropList 
                                        id="groupinglist2"
                                        [cdkDropListData]="skillGroup2"
                                        [cdkDropListConnectedTo]="['leftSideSkills', 'groupinglist1', 'groupinglist3', 'groupinglist4']"
                                        (cdkDropListDropped)="isSkillGroup2Enabled && dropGroup2($event)"
                                        [ngClass]="isSkillGroup2Enabled ? 'obs-multi-select-skill-box' : 'obs-multi-select-skill-box-disabled'">
                                        <li
                                            *ngFor="let item of skillGroup2; index as i"
                                            [ngClass]="isReadOnly === true ? 'li-disabled' : 'obs-option'"
                                            draggable="isSkillGroup2Enabled === true && isReadOnly === false"   
                                            title=""
                                            cdkDrag
                                            [cdkDragDisabled]="isReadOnly">
                                            <div>
                                                <div class="skill-custom-placeholder" *cdkDragPlaceholder></div>
                                                {{ item.viewValue }}
                                            </div>
                                            <i *ngIf="!isReadOnly"
                                                class="material-icons remove-list-row"
                                                (click)="rightOption2Click(i, item)">remove</i>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="halign">
                                <div id="group3divdd">
                                    <mat-form-field fxFlex="fieldLength" class="col-md-12 no-padding-left" (click)="displaySkillGroupingPickList($event, 'group3')">
                                        <mat-label>Skill Grouping 3</mat-label>
                                          <input matInput 
                                          [formControl]="expertiseType3" 
                                          type="text"
                                          placeholder="Select Skill Grouping"
                                          aria-label="Skill Grouping 3"
                                          name="expertiseType3Input"
                                          value="getInputControlValue(expertiseType3)"
                                          (keydown)="displaySkillGroupingPickList($event, 'group3')"/>
                                        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                                      </mat-form-field>
                                </div>
                                <div id="group3divddlist">
                                    <ul cdkDropList 
                                        id="groupinglist3"
                                        [cdkDropListData]="skillGroup3"
                                        [cdkDropListConnectedTo]="['leftSideSkills', 'groupinglist1', 'groupinglist2', 'groupinglist4']"
                                        (cdkDropListDropped)="isSkillGroup3Enabled && dropGroup3($event)"
                                        [ngClass]="isSkillGroup3Enabled ? 'obs-multi-select-skill-box' : 'obs-multi-select-skill-box-disabled'">
                                        <li
                                            *ngFor="let item of skillGroup3; index as i"
                                            [ngClass]="isReadOnly === true ? 'li-disabled' : 'obs-option'"
                                            draggable="isSkillGroup3Enabled === true && isReadOnly === false"   
                                            title="" 
                                            cdkDrag
                                            [cdkDragDisabled]="isReadOnly">
                                            <div>
                                                <div class="skill-custom-placeholder" *cdkDragPlaceholder></div>
                                                {{ item.viewValue }}
                                            </div>
                                            <i *ngIf="!isReadOnly"
                                                class="material-icons remove-list-row"
                                                (click)="rightOption3Click(i, item)">remove</i>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="halign">
                                <div id="group4divdd">
                                    <mat-form-field fxFlex="fieldLength" class="col-md-12 no-padding-left" (click)="displaySkillGroupingPickList($event, 'group4')">
                                        <mat-label>Skill Grouping 4</mat-label>
                                          <input matInput 
                                          [formControl]="expertiseType4" 
                                          type="text"
                                          placeholder="Select Skill Grouping"
                                          aria-label="Skill Grouping 4"
                                          name="expertiseType4Input"
                                          value="getInputControlValue(expertiseType4)"
                                          (keydown)="displaySkillGroupingPickList($event, 'group4')"/>
                                        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                                      </mat-form-field>
                                </div>
                                <div id="group4divddlist">
                                    <ul cdkDropList 
                                        id="groupinglist4"
                                        [cdkDropListData]="skillGroup4"
                                        [cdkDropListConnectedTo]="['leftSideSkills', 'groupinglist1', 'groupinglist2', 'groupinglist3']"
                                        (cdkDropListDropped)="isSkillGroup4Enabled && dropGroup4($event)"
                                        [ngClass]="isSkillGroup4Enabled ? 'obs-multi-select-skill-box' : 'obs-multi-select-skill-box-disabled'">
                                        <li
                                            *ngFor="let item of skillGroup4; index as i"
                                            [ngClass]="isReadOnly === true ? 'li-disabled' : 'obs-option'"
                                            draggable="isSkillGroup4Enabled === true && isReadOnly === false"   
                                            title=""
                                            cdkDrag
                                            [cdkDragDisabled]="isReadOnly">
                                            <div>
                                                <div class="skill-custom-placeholder" *cdkDragPlaceholder></div>
                                                {{ item.viewValue }}
                                            </div>
                                            <i *ngIf="!isReadOnly"
                                                class="material-icons remove-list-row"
                                                (click)="rightOption4Click(i, item)">remove</i>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </form>
            </div>
        </div>
    </div>
</mat-card>
import { UntypedFormControl, UntypedFormArray, UntypedFormGroup } from "@angular/forms";
import { Component, EventEmitter, OnInit, Input, Output } from "@angular/core";
import { DropDown } from "src/app/common/services/common.service";
import { MatDialog } from "@angular/material/dialog";
import {
  ConfirmDialog,
  ConfirmationDialogComponent,
} from "src/app/shared/confirmation-dialog/confirmation-dialog.component";

export class MultiSelectOption<T> implements DropDown<T> {
  id: number;
  value: number;
  viewValue: string;
  dateModified: Date;
  element: any;
  isSelected: boolean;
  icon?: string;

  constructor(dropDown: DropDown<T>, isSelected: boolean = false) {
    this.id = dropDown.id;
    this.value = dropDown.value;
    this.viewValue = dropDown.viewValue;
    this.dateModified = dropDown.dateModified;
    this.element = dropDown.element;
    this.isSelected = isSelected;
    this.icon = dropDown.icon;
  }
}

@Component({
  selector: "obs-multi-select-card",
  templateUrl: "multi-select-card.component.html",
  styleUrls: ["multi-select-card.component.scss"],
})
export class MultiSelectCardComponent<T> implements OnInit {
  @Input() parentForm: UntypedFormGroup;
  @Input() formArrayName: string;
  @Input() objectDropDown: DropDown<T>[];
  @Input() title: string;
  @Input() fieldLabel: string;
  @Input() readOnlyForm: boolean;
  @Input() maxItems: number;
  @Input() leftTitle: string;
  @Input() rightTitle: string;
  @Input() refreshList: boolean;
  @Input() sortList: (
    list: Map<number, DropDown<T>>
  ) => Map<number, DropDown<T>>;
  @Input() sortRightList: (
    list: Map<number, DropDown<T>>
  ) => Map<number, DropDown<T>>;
  // output is error message
  @Input() validate: (
    selected: DropDown<T>,
    current: Map<number, DropDown<T>>
  ) => string;

  @Output() onRightSideChange: EventEmitter<DropDown<T>[]> = new EventEmitter();

  constructor(private dialog: MatDialog) {}

  private _leftList = new Map<number, DropDown<T>>();
  private _rightList = new Map<number, DropDown<T>>();
  leftSide: MultiSelectOption<T>[];
  rightSide: MultiSelectOption<T>[];
  isPopulated: boolean;
  isEmpty: boolean;
  errorMessage: string;

  get formArray() {
    return this.parentForm.get(this.formArrayName) as UntypedFormArray;
  }

  get isReadOnly() {
    return this.readOnlyForm;
  }

  ngOnInit(): void {
    let tempList = new Map<string, DropDown<T>>();

    for (let i = 0; i < this.objectDropDown.length; i++) {
      let tempElement = this.objectDropDown[i];

      if (
        !tempList.has(tempElement.viewValue) ||
        tempList.get(tempElement.viewValue).dateModified <
          tempElement.dateModified
      ) {
        tempList.set(tempElement.viewValue, tempElement);
      }
    }

    tempList.forEach((item) => {
      this._leftList.set(item.value, item);
    });

    this.formArray.controls.forEach((item) => {
      let includedItem = this._leftList.get(item.value);
      if (includedItem) {
        this._rightList.set(includedItem.value, includedItem);
        this._leftList.delete(includedItem.value);
      }
    });

    this.toggleSelectAllButtons();
    this.populateSides();
  }

  ngOnChanges() {
    if (this.refreshList) {
      this.populateSides();
    }
  }

  alert(message?: any): void {
    alert(message);
  }

  getSortedDropDown(
    dropDownMap: Map<number, DropDown<T>>
  ): Map<number, DropDown<T>> {
    if (this.sortList) return this.sortList(dropDownMap);
    return new Map(
      [...dropDownMap].sort((a, b) =>
        String(a[1].viewValue.toUpperCase()).localeCompare(
          b[1].viewValue.toUpperCase()
        )
      )
    );
  }

  getSortedRightDropDown(
    dropDownMap: Map<number, DropDown<T>>
  ): Map<number, DropDown<T>> {
    if (this.sortRightList) return this.sortRightList(dropDownMap);
    return dropDownMap;
  }

  populateSides(): void {
    this.leftSide = [];
    this.getSortedDropDown(this._leftList).forEach((item) => {
      let newItem = new MultiSelectOption(item);
      this.leftSide.push(newItem);
    });
    this.rightSide = [];
    this.getSortedRightDropDown(this._rightList).forEach((item) => {
      let newItem = new MultiSelectOption(item);
      this.rightSide.push(newItem);
    });
  }

  clearErrorMessage(): void {
    this.errorMessage = null;
  }

  leftOptionClick(index: number, selected: DropDown<T>): void {
    if (!this.isReadOnly) {
      this.errorMessage = this.validate
        ? this.validate(selected, this._rightList)
        : null;
      if (this.errorMessage) return;

      this._leftList.delete(selected.value);
      this._rightList.set(selected.value, selected);

      this.populateSides();

      this.onRightSideChange.emit(this.rightSide);
      this.parentForm.markAsDirty();
      this.toggleSelectAllButtons();
    }
  }

  rightOptionClick(index: number, selected: DropDown<T>): void {
    if (!this.isReadOnly) {
      this.clearErrorMessage();

      this._leftList.set(selected.value, selected);
      this._rightList.delete(selected.value);

      this.populateSides();
      this.onRightSideChange.emit(this.rightSide);
      this.parentForm.markAsDirty();
      this.toggleSelectAllButtons();
    }
  }

  clearSelectedItems(): void {
    var selectedItems = new Map(this._rightList);

    const confirmDialog = this.showConfirmationDialog(
      "Clear All Items",
      "Are you sure you want to clear all items included in the resume?",
      "Yes",
      "No"
    );

    confirmDialog.afterClosed().subscribe((result) => {
      if (result === "ok") {
        selectedItems.forEach((item) => {
          this.rightOptionClick(item.id, item);
        });

        this.toggleSelectAllButtons();
      }
    });
  }

  addAllItems(): void {
    var selectedItems = new Map(this._leftList);

    selectedItems.forEach((item) => {
      this.leftOptionClick(item.id, item);
    });

    this.toggleSelectAllButtons();
  }

  toggleSelectAllButtons(): void {
    this.isPopulated = this._rightList.size > 0;
    this.isEmpty = this._leftList.size > 0;
  }

  private showConfirmationDialog(
    title: string,
    message: string,
    okButtonTitle: string,
    cancelButtonTitle: string = undefined,
    flipButtonColor: boolean = false
  ): any {
    const confirmDialog = new ConfirmDialog();

    confirmDialog.title = title;
    confirmDialog.message = message;
    confirmDialog.okButtonTitle = okButtonTitle;
    confirmDialog.flipButtonColor = flipButtonColor;
    if (cancelButtonTitle) {
      confirmDialog.cancelButtonTitle = cancelButtonTitle;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data: confirmDialog,
      disableClose: true,
    });

    return dialogRef;
  }

  public requiredIsEmpty(testValue: UntypedFormControl): boolean {
    if (
      (this.fieldLabel == "Skill Summary" ||
        this.fieldLabel == "Skill" ||
        this.fieldLabel == "Education" ||
        this.fieldLabel == "Project" ||
        this.fieldLabel == "Work Experience") &&
      testValue.value <= 0
    ) {
      return true;
    } else {
      return false;
    }
  }
}
